// components/DatePickerComponent.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fields.css";

const DatePickerComponent = ({
  selectedDate,
  onChange,
  filterDate,
  minDate,
  placeholderText,
  validation,
  ariaLabel = null,
  required = false,
}) => (
  <div
    className="form-control mb-3 rounded bg-soft-dark"
    style={{ borderWidth: "medium" }}
  >
    <DatePicker
      className="rounded border-0 react-datepicker__input-container"
      // style={{ width: "10%" }}
      placeholderText={placeholderText}
      dateFormat={["dd MMMM, yyyy", "ddMMyyyy", "dd-MM-yyyy", "dd/MM/yyyy"]}
      autofocus={false}
      selected={selectedDate}
      onChange={onChange}
      filterDate={filterDate}
      minDate={minDate}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      aria-label={ariaLabel}
      aria-required={required}
    />
    {validation && !validation.valid && (
      <span className="text-danger" style={{ fontSize: "smaller" }}>
        {validation.message}
      </span>
    )}
  </div>
);

export default DatePickerComponent;
