import axios from "axios";
import moment from "moment";

const baseUrl = process.env.REACT_APP_sales_base;

export async function searchCustomerAppointments(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/query/`,
    criteria,
    config,
  );
  return result.data.sort(
    (a, b) =>
      moment(a.appointment_booked_dttm) - moment(b.appointment_booked_dttm),
  );
}

export async function searchCustomerOrders(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/orders/query/`,
    criteria,
    config,
  );
  return result.data;
}

export async function getOrderBooking(bookind_id) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.get(
    `${baseUrl}/api/orders/booking/${bookind_id}`,
    config,
  );
  return result.data;
}

export async function getOverBookedSlots(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/get-over-booked-slots`,
    payload,
    config,
  );
  return result.data;
}

export async function prepareAppointments(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/prepare`,
    payload,
    config,
  );
  return result.data;
}

export async function initiateAppointments(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/initiate`,
    payload,
    config,
  );
  return result.data;
}

export async function finalizeNhsAppointments(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/finalize/nhs`,
    payload,
    config,
  );
  return result.data;
}

export async function finalizePrivateAppointments(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/finalize/private`,
    payload,
    config,
  );
  return result.data;
}

export async function getInvoiceData(bookingId) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.get(
    `${baseUrl}/api/appointments/invoice/${bookingId}`,
    config,
  );
  return result.data;
}

export async function finalizeNhsBookings(data) {
  if (data.serviceOrderData && data.nhsServicesPaymentIntents) {
    // Save Data
    finalizeNhsAppointments({
      nhsServicesPaymentIntents: data.nhsServicesPaymentIntents,
      nhs: data.serviceOrderData["nhs"],
    })
      .then((r) => {
        console.log("hii");
        if (
          !data.serviceOrderData.priv ||
          Object.keys(data.serviceOrderData.priv.data).length === 0
        ) {
          console.log("hi");
          window.open(
            `/book/services/invoice?bookingId=${data.serviceOrderData.bookingId}`,
            "_self",
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export async function rescheduleAppointment(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/reschedule`,
    payload,
    config,
  );
  return result.data;
}

export async function patientCancel(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/patient-cancel`,
    payload,
    config,
  );
  return result.data;
}

export async function getPatientPortalAppointments(payee_customer_id) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.get(
    `${baseUrl}/api/appointments/customer/${payee_customer_id}`,
    config,
  );
  return result.data.sort(
    (b, a) =>
      moment(a.appointment_start_dttm) - moment(b.appointment_start_dttm),
  );
}
