import React, { Fragment, useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import NewHeader from "../../components/header/header";
import ScrollTop from "../../components/scrollTop";
import {
  createCustomer,
  searchCustomerViaEmail,
} from "../../utils/usermgmt.service";
import {
  birthdateValidator,
  emailValidator,
  phoneValidator,
  postalCodeValidator,
} from "../../utils/validators";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

const SignIn = () => {
  // Variables
  const auth0 = useAuth0();
  const [emailAddress, setEmailAddress] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [showSignUpButton, setShowSignUpButton] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("United Kingdom");
  const [postCode, setPostCode] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [gender, setGender] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [customerCreated, setCustomerCreated] = useState(null);
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: true,
    message: "First Name Is Required",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: true,
    message: "Last Name Is Required",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    message: "Email Is Required",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: true,
    message: "Phone Is Required",
  });
  const [addressValidation, setAddressValidaton] = useState({
    valid: true,
    message: "Address Line 1 Is Required",
  });
  const [cityValidation, setCityValidation] = useState({
    valid: true,
    message: "City Is Required",
  });
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    valid: true,
    message: "Postal Code Is Required",
  });
  const [birthdateValidation, setBirthdateValidation] = useState({
    valid: true,
    message: "Birthdate Is Required",
  });
  const [genderValidation, setGenderValidation] = useState({
    valid: true,
    message: "Gender Is Required",
  });

  // Styles
  const containerStyle = {
    display: "flex",
    flexDirection: "column", // Stacks the label above the input
    justifyContent: "start",
    alignItems: "center",
    height: "100vh", // Full viewport height
  };

  const labelStyle = {
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "bold",
  };

  const emailInputStyle = {
    marginTop: "10px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "95%",
    maxWidth: "380px", // Limit the input field width
  };

  const formStyle = {
    maxHeight: showForm ? "1000px" : "0",
    overflow: "scroll",
    transition: "max-height 0.5s ease-in-out",
    width: "100%",
    maxWidth: "400px",
    borderRadius: "15px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  const inputStyle = {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "95%",
    marginBottom: "10px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#007bff",
    color: "white",
    cursor: "pointer",
    marginTop: "20px",
  };

  // Handlers
  const handleSignUpClick = () => {
    setShowForm(true);
    setShowSignUpButton(false);
  };

  // Functions
  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };
  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value || e.target.value === firstName) {
      setLastNameValidation({
        valid: false,
        message: "Unique last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };
  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneValidator(e.target.value)) {
      setPhoneNumberValidation({ valid: true, message: "" });
    } else {
      setPhoneNumberValidation({
        valid: false,
        message: "Please Enter Valid Phone Number of format 01111111111",
      });
    }
  };
  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };
  const address1Change = (e) => {
    setAddress1(e.target?.value);
    if (!e.target?.value) {
      setAddressValidaton({
        valid: false,
        message: "Address Line 1 Is Required",
      });
    } else {
      setAddressValidaton({ valid: true, message: "" });
    }
  };
  const address2Change = (e) => {
    setAddress2(e.target.value);
  };
  const cityChange = (e) => {
    setCity(e.target?.value);
    if (!e.target?.value) {
      setCityValidation({
        valid: false,
        message: "City Is Required",
      });
    } else {
      setCityValidation({ valid: true, message: "" });
    }
  };
  const postCodeChange = (e) => {
    setPostCode(e.target?.value);
    if (postalCodeValidator(e.target?.value)) {
      setPostalCodeValidation({ valid: true, message: "" });
    } else {
      setPostalCodeValidation({
        valid: false,
        message: "Please Enter Valid Postal Code",
      });
    }
  };
  const handleBirthdateDate = (date) => {
    // When Selecting Date, ignore time zone by doing start of day
    if (date) {
      date = moment([
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      ]).format("YYYY-MM-DD");
      setBirthdate(date);
    }
    if (birthdateValidator(date)) {
      setBirthdateValidation({ valid: true, message: "" });
    } else {
      setBirthdateValidation({
        valid: false,
        message: "Please Enter Valid Birthdate",
      });
    }
  };
  const selectCountry = (e) => {
    // Set Value
    setCountry(e.target.value);
  };
  const genderUpdate = (e) => {
    if (["Male", "Female"].includes(e.target.value)) {
      setGender(e.target.value);
      setGenderValidation({ valid: true, message: "Gender Is Required" });
    } else {
      setGender(e.target.value);
      setGenderValidation({ valid: false, message: "Gender Is Required" });
    }
  };
  const handleSubmit = () => {
    let payload = {
      first_nm: firstName,
      last_nm: lastName,
      user_nm: emailAddress,
      customer_phone: phoneNumber,
      customer_email: emailAddress,
      customer_birth_dt: birthdate,
      customer_addr1: address1,
      customer_addr2: address2,
      customer_city: city,
      customer_zip: postCode,
      customer_country: country,
      customer_gender: gender,
    };

    createCustomer(payload)
      .then((result) => {
        if (result) {
          setCustomerCreated(result);
        }
      })
      .catch((err) => {});
  };

  // Use Effect
  useEffect(() => {
    if (auth0.isAuthenticated) {
      setIsAuthenticated(true);
      window.open(
        `${process.env.REACT_APP_BACKOFFICE_BASE}/authenticate`,
        "_self",
      );
    } else {
      setIsAuthenticated(false);
    }
  }, [auth0]);

  // Look for User
  useEffect(() => {
    const emailInput = document.getElementById("emailAddress");
    setShowForm(false);
    if (emailInput.validity.valid) {
      setEmailValidation({
        valid: true,
        message: "Please Enter Valid Email Address",
      });
      searchCustomerViaEmail({ email_addr: emailAddress })
        .then((r) => {
          if (r.length > 0) {
            setUserFound(r[0]);
            setShowSignUpButton(false);
          } else {
            setShowSignUpButton(true);
          }
        })
        .catch((err) => {});
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  }, [emailAddress]);

  // Look for User
  useEffect(() => {
    if (emailAddress && emailAddress !== "") {
      searchCustomerViaEmail({ email_addr: emailAddress })
        .then((r) => {
          if (r.length > 0) {
            setUserFound(r[0]);
          }
        })
        .catch((err) => {});
    }
  }, [emailAddress]);

  // Sign In
  useEffect(() => {
    if (userFound) {
      sessionStorage.clear();
      localStorage.clear();
      auth0
        .loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_frontoffice_base}/patient-profile`,
            login_hint: userFound.email_addr,
            screen_hint: "login",
          },
        })
        .then((data) => {});
    }
  }, [auth0, userFound]);

  // Sign Up
  useEffect(() => {
    if (customerCreated) {
      sessionStorage.clear();
      localStorage.clear();
      auth0
        .loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_frontoffice_base}/patient-profile`,
            login_hint: emailAddress,
            screen_hint: "signup",
          },
        })
        .then((data) => {});
    }
  }, [auth0, customerCreated, emailAddress]);

  // Redirect
  useEffect(() => {
    if (!isAuthenticated) {
      // TODO
    }
  }, [isAuthenticated]);

  // Form Validator
  useEffect(() => {
    setValidForm(
      firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        postalCodeValidation.valid &&
        addressValidation.valid &&
        cityValidation.valid &&
        birthdateValidation.valid &&
        genderValidation.valid,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    addressValidation,
    cityValidation,
    postalCodeValidation,
    birthdateValidation,
    genderValidation,
  ]);

  return (
    <Fragment>
      <NewHeader />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- Patient Login</h1>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Enter your email address to continue:
            </label>
            <input
              className="col-12"
              id="emailAddress"
              type="email"
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              placeholder="Enter your email"
              style={emailInputStyle}
            />
            {!emailValidation.valid ? (
              <span className="text-danger" style={{ fontSize: "smaller" }}>
                {" "}
                {emailValidation.message}{" "}
              </span>
            ) : null}
            {userFound && (
              <label
                className="mt-2 text-primary"
                style={labelStyle}
              >{`Welcome, ${userFound.first_nm}`}</label>
            )}
            {emailAddress && showSignUpButton && (
              <button style={buttonStyle} onClick={handleSignUpClick}>
                Sign Up
              </button>
            )}

            <div className="mt-4 border-1" style={formStyle}>
              {showForm && (
                <>
                  <label className="text-start text-primary">
                    User Profile
                  </label>
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="First Name"
                    style={inputStyle}
                    value={firstName}
                    onChange={firstNameChange}
                  />
                  {!firstNameValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {firstNameValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Last Name"
                    style={inputStyle}
                    value={lastName}
                    onChange={lastNameChange}
                  />
                  {!lastNameValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {lastNameValidation.message}{" "}
                    </span>
                  ) : null}
                  <div className="text-start mx-2" style={inputStyle}>
                    <DatePicker
                      className={"border-0 w-full text-start"}
                      placeholderText="Choose Your Birthdate"
                      dateFormat={[
                        "dd MMMM, yyyy",
                        "ddMMyyyy",
                        "dd-MM-yyyy",
                        "dd/MM/yyyy",
                      ]}
                      locale="en-GB"
                      selected={birthdate}
                      maxDate={new Date()}
                      onChange={handleBirthdateDate}
                      placeholder={"Choose Your Birthdate"}
                      autoFocus={false}
                      style={{ position: "relative" }}
                    />
                  </div>
                  {!birthdateValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {birthdateValidation.message}{" "}
                    </span>
                  ) : null}
                  <select
                    className="mt-2"
                    style={inputStyle}
                    onChange={genderUpdate}
                    value={gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {!genderValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {genderValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Phone Number"
                    style={inputStyle}
                    value={phoneNumber}
                    onChange={phoneNumberChange}
                  />
                  {!phoneNumberValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {phoneNumberValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Address Line 1"
                    style={inputStyle}
                    value={address1}
                    onChange={address1Change}
                  />
                  {!addressValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {addressValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Address Line 2"
                    style={inputStyle}
                    value={address2}
                    onChange={address2Change}
                  />
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="City"
                    style={inputStyle}
                    value={city}
                    onChange={cityChange}
                  />
                  {!cityValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {cityValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Postal Code"
                    style={inputStyle}
                    value={postCode}
                    onChange={postCodeChange}
                  />
                  {!postalCodeValidation.valid ? (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      {postalCodeValidation.message}{" "}
                    </span>
                  ) : null}
                  <input
                    disabled={true}
                    className="mt-2"
                    type="text"
                    placeholder="Country"
                    style={inputStyle}
                    value={country}
                  />
                  <input
                    type="button"
                    id="submit"
                    name="send"
                    // className="btn btn-primary col-4 mx-2"
                    style={{
                      ...inputStyle,
                      backgroundColor: "#017bff",
                      color: "#ffffff",
                    }}
                    value="Save"
                    disabled={
                      !validForm ||
                      !firstName ||
                      !lastName ||
                      !phoneNumber ||
                      !birthdate ||
                      !address1 ||
                      !city ||
                      !postCode ||
                      !gender
                    }
                    onClick={handleSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
};

export default withAuth0(SignIn);
