// BookAppointmentForm.js
import React, { useEffect, useReducer, useRef, useState } from "react";
import moment from "moment";
import { createPdfBlobUrl } from "../../../utils/document.utils";
import {
  birthdateValidator,
  emailValidator,
  phoneValidator,
  postalCodeValidator,
} from "../../../utils/validators";
import { orderDataPayloadNew } from "../../../utils/payloads.utils";
import {
  multiAppointmentServices,
  servicesInteractiveForm,
  servicesSuitabilityForm,
} from "../../../data/data";

import InputField from "../../fields/InputField";
import DatePickerComponent from "../../fields/DatePickerComponent";
import TimePickerComponent from "../../fields/TimePickerComponent";
import ConsentForm from "./ConsentForm";
import InteractiveForm from "./InteractiveForm";
import useCustomerData from "../../../hooks/useCustomerData";
import usePractitionerData from "../../../hooks/usePractitionerData";
import { setHours, setMinutes } from "date-fns";
import Radar from "radar-sdk-js";
import { formatBookUrl } from "../../../utils/helper";
import { Link } from "react-router-dom";

const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  serviceMethod: "",
  serviceRequestNotes: "",
  address1: "",
  address2: "",
  city: "",
  country: "United Kingdom",
  postCode: "",
  gender: "",
};

function formReducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
}

const BookAppointmentForm = ({
  data,
  handleOrderData,
  handlePaymentData,
  serviceAddToCart,
  resetSelection,
  serviceType,
}) => {
  // Styles
  const inputStyle = {
    padding: "10px",
    // paddingTop: "0px",
    // paddingBottom: "0px",
    margin: "0px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "0.5px solid #ccc",
    width: "100%",
  };

  // State and Reducer
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [bookingStep, setBookingStep] = useState(1);

  // Session and Customer Data
  const sessionCustomerId = sessionStorage.getItem("customerId");
  const [customerId] = useState(sessionCustomerId);
  const customer = useCustomerData(customerId);
  const autocompleteRef1 = useRef(null);
  const autocompleteRef2 = useRef(null);
  const [birthday, setBirthday] = useState(null);

  // Other State Variables
  const [practitioner, setPractitioner] = useState(null);
  const [service, setService] = useState(null);
  const [businessDays, setBusinessDays] = useState(null);
  const [businessStartTime, setBusinessStartTime] = useState(null);
  const [businessEndTime, setBusinessEndTime] = useState(null);

  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startTimeFormatted, setStartTimeFormatted] = useState(null);
  const [ageConsent, setAgeConsent] = useState(false);
  const [consent, setConsent] = useState(false);
  const [informationConsent, setInformationConsent] = useState(false);
  const [showConsentLink, setShowConsentLink] = useState(false);
  const [consentForm, setConsentForm] = useState(null);
  const [showInformationLink, setShowInformationLink] = useState(false);
  const [informationLink, setInformationLink] = useState(null);
  const [showMultiAppointmentConsent, setShowMultiAppointmentConsent] =
    useState(false);
  const [multiAppointmentConsent, setMultiAppointmentConsent] = useState(null);

  const [interactiveForm, setInteractiveForm] = useState([]);
  const [interactiveFormData, setInteractiveFormData] = useState([]);
  const [showInteractiveForm, setShowInteractiveForm] = useState(false);

  const [serviceMethods, setServiceMethods] = useState([]);
  const [validForm, setValidForm] = useState(false);

  const [suitabilityForm, setSuitabilityForm] = useState([]);
  const [suitabilityFormData, setSuitabilityFormData] = useState([]);
  const [unSuitabilityForm, setUnSuitabilityForm] = useState([]);
  const [unSuitabilityFormData, setUnSuitabilityFormData] = useState([]);
  const [showSuitabilityForm, setShowSuitabilityForm] = useState(false);
  const [showUnSuitabilityForm, setShowUnSuitabilityForm] = useState(false);
  const [suitabilityError, setSuitabilityError] = useState(false);

  // Validation States
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: false,
    message: "",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: false,
    message: "",
  });
  const [methodValidation, setMethodValidation] = useState({
    valid: false,
    message: "",
  });
  const [addressValidation, setAddressValidaton] = useState({
    valid: false,
    message: "",
  });
  const [cityValidation, setCityValidation] = useState({
    valid: false,
    message: "",
  });
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    valid: false,
    message: "",
  });
  const [birthdateValidation, setBirthdateValidation] = useState({
    valid: false,
    message: "",
  });
  const [genderValidation, setGenderValidation] = useState({
    valid: false,
    message: "Please Select Gender",
  });
  const [appointmentDateValidation, setAppointmentDateValidation] = useState({
    valid: false,
    message: "Please Select Date",
  });
  const [appointmentTimeValidation, setAppointmentTimeValidation] = useState({
    valid: false,
    message: "",
  });

  // Use custom hooks for practitioner data
  const { overbookedSlots, practitionerBlockedSlots } = usePractitionerData(
    practitioner,
    startDate,
  );

  // Pre-populate form with customer data
  useEffect(() => {
    if (customer) {
      if (customer?.user?.first_nm) {
        dispatch({
          type: "SET_FIELD",
          field: "firstName",
          value: customer?.user?.first_nm,
        });
        setFirstNameValidation({ valid: true, message: "" });
      }

      if (customer?.user?.last_nm) {
        dispatch({
          type: "SET_FIELD",
          field: "lastName",
          value: customer?.user?.last_nm,
        });
        setLastNameValidation({ valid: true, message: "" });
      }

      if (customer?.customer_email) {
        dispatch({
          type: "SET_FIELD",
          field: "emailAddress",
          value: customer?.customer_email,
        });
        setEmailValidation({
          valid: emailValidator(customer?.customer_email),
          message: "Please Enter Valid Email Address",
        });
      }

      if (customer?.customer_phone) {
        dispatch({
          type: "SET_FIELD",
          field: "phoneNumber",
          value: customer?.customer_phone,
        });
        setPhoneNumberValidation({
          valid: phoneValidator(customer.customer_phone),
          message: "Please Enter Valid Phone Number",
        });
      }
      if (customer?.customer_addr1) {
        dispatch({
          type: "SET_FIELD",
          field: "address1",
          value: customer?.customer_addr1,
        });
        setAddressValidaton({
          valid: true,
          message: "",
        });
      }
      if (customer?.customer_addr2) {
        dispatch({
          type: "SET_FIELD",
          field: "address2",
          value: customer?.customer_addr1,
        });
      }
      if (customer?.customer_zip) {
        dispatch({
          type: "SET_FIELD",
          field: "postCode",
          value: customer?.customer_zip,
        });
        setPostalCodeValidation({
          valid: postalCodeValidator(customer.customer_zip),
          message: "Please Enter Valid Postal Code",
        });
      }
      if (customer?.customer_city) {
        dispatch({
          type: "SET_FIELD",
          field: "city",
          value: customer?.customer_city,
        });
        setCityValidation({
          valid: true,
          message: "",
        });
      }
      if (customer?.customer_country) {
        dispatch({
          type: "SET_FIELD",
          field: "country",
          value: customer?.customer_country,
        });
      }
      if (customer?.customer_gender) {
        dispatch({
          type: "SET_FIELD",
          field: "gender",
          value: customer?.customer_gender,
        });
      }
      if (customer?.customer_birth_dt) {
        setBirthday(customer?.customer_birth_dt);
        setBirthdateValidation({
          valid: birthdateValidator(
            moment(
              customer?.customer_birth_dt
                ? customer?.customer_birth_dt
                : new Date(),
            )
              .utc()
              .format(),
          ),
          message: "Patient must be 18 years old.",
        });
      }
    }
  }, [customer]);

  // Update validity of the form
  useEffect(() => {
    setValidForm(
      practitioner &&
        service &&
        firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        methodValidation.valid &&
        addressValidation.valid &&
        cityValidation.valid &&
        postalCodeValidation.valid &&
        birthdateValidation.valid &&
        startDate &&
        startTime &&
        ageConsent &&
        !suitabilityError,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    methodValidation,
    startDate,
    startTime,
    suitabilityError,
    ageConsent,
    practitioner,
    service,
    addressValidation,
    cityValidation,
    postalCodeValidation,
    birthdateValidation,
  ]);

  // Set service and practitioner data
  useEffect(() => {
    if (data && data.serviceSearchData) {
      setService(data.serviceSearchData.service);
      setPractitioner(data.serviceSearchData.practitionerData);
      setBusinessDays(
        data.serviceSearchData.practitionerData.business_days_json,
      );
      setBusinessStartTime(
        data.serviceSearchData.practitionerData.business_hours_start_tm_json,
      );
      setBusinessEndTime(
        data.serviceSearchData.practitionerData.business_hours_close_tm_json,
      );

      // Consent Form
      if (data.serviceSearchData.consentTemplate) {
        setShowConsentLink(true);
        setConsentForm(data.serviceSearchData.consentTemplate[0]);
      } else {
        setShowConsentLink(false);
      }

      // Service Methods
      const consultationTypes =
        data.serviceSearchData.service.consultation_type;
      const methods = Object.keys(consultationTypes).filter(
        (key) => consultationTypes[key],
      );
      setServiceMethods(methods);
      dispatch({
        type: "SET_FIELD",
        field: "serviceMethod",
        value: "In-Person",
      });
      setMethodValidation({
        valid: true,
        message: "Method Is Required",
      });

      // Interactive Form
      const interactiveData =
        servicesInteractiveForm[data.serviceSearchData.serviceId.toLowerCase()];
      if (interactiveData && interactiveData.nhsConsentLink) {
        setShowInformationLink(true);
        setInformationLink(interactiveData.nhsConsentLink);
        setInteractiveForm(interactiveData.questionnaire);
        setInteractiveFormData(
          new Array(interactiveData.questionnaire.length).fill(false),
        );
        setShowInteractiveForm(interactiveData.questionnaire.length > 0);
      } else {
        setShowInformationLink(false);
        setShowInteractiveForm(false);
      }

      // Suitability Form
      const suitabilityData =
        servicesSuitabilityForm[data.serviceSearchData.serviceId.toLowerCase()];
      if (suitabilityData) {
        if (suitabilityData.suitable && suitabilityData.suitable.length > 0) {
          setShowSuitabilityForm(true);
          setSuitabilityForm(suitabilityData.suitable);
          setSuitabilityFormData(
            new Array(suitabilityData.suitable.length).fill(false),
          );
          setSuitabilityError(true);
        }
        if (
          suitabilityData.unsuitable &&
          suitabilityData.unsuitable.length > 0
        ) {
          setShowUnSuitabilityForm(true);
          setUnSuitabilityForm(suitabilityData.unsuitable);
          setUnSuitabilityFormData(
            new Array(suitabilityData.unsuitable.length).fill(false),
          );
        }
      }

      // Multi Appointments
      if (
        multiAppointmentServices.includes(
          data.serviceSearchData.serviceId.toLowerCase(),
        )
      ) {
        setShowMultiAppointmentConsent(true);
        setMultiAppointmentConsent(false);
      } else {
        setShowMultiAppointmentConsent(false);
        setMultiAppointmentConsent(true);
      }
    } else {
      // Reset state if no data
      setService(null);
      setPractitioner(null);
      setBusinessDays(null);
      setBusinessStartTime(null);
      setBusinessEndTime(null);
      setAgeConsent(false);
      setConsent(false);
      setInformationConsent(false);
      dispatch({ type: "SET_FIELD", field: "serviceMethod", value: "" });
      dispatch({ type: "SET_FIELD", field: "serviceRequestNotes", value: "" });
      setStartDate(null);
      setStartTime(null);
      // Information Form -- Suitability Form -- Unsuitability Form
      setShowInformationLink(false);
      setShowInteractiveForm(false);
      setInformationLink(null);
      setInteractiveForm([]);
      setInteractiveFormData([]);
      setShowSuitabilityForm(false);
      setSuitabilityForm([]);
      setSuitabilityFormData([]);
      setSuitabilityError(false);
      setShowUnSuitabilityForm(false);
      setUnSuitabilityForm([]);
      setUnSuitabilityFormData([[]]);
      setShowMultiAppointmentConsent(false);
      setMultiAppointmentConsent(true);
    }
  }, [data]);

  // Radar
  useEffect(() => {
    Radar.initialize(process.env.REACT_APP_RADAR_PK);
    try {
      autocompleteRef1.current = Radar.ui.autocomplete({
        container: "autocomplete_city",
        width: "1200px",
        onSelection: (address) => {
          if (address.city) {
            handleFieldChange("city");
          }

          if (address.postalCode) {
            handleFieldChange("postCode");
          }
        },
      });

      autocompleteRef2.current = Radar.ui.autocomplete({
        container: "autocomplete_postal",
        width: "1200px",
        onSelection: (address) => {
          if (address.city) {
            handleFieldChange("city");
          }

          if (address.postalCode) {
            handleFieldChange("postCode");
          }
        },
      });
    } catch (err) {}

    return () => {
      autocompleteRef1.current?.remove();
      autocompleteRef2.current?.remove();
    };
  }, []);

  // Handlers for input fields
  const handleFieldChange = (field) => (e) => {
    dispatch({ type: "SET_FIELD", field, value: e.target.value });
    // Validation logic
    switch (field) {
      case "firstName":
        setFirstNameValidation({
          valid: !!e.target.value,
          message: "First Name Is Required",
        });
        break;
      case "lastName":
        setLastNameValidation({
          valid: !!e.target.value,
          message: "Last Name Is Required",
        });
        break;
      case "emailAddress":
        setEmailValidation({
          valid: emailValidator(e.target.value),
          message: "Please Enter Valid Email Address",
        });
        break;
      case "phoneNumber":
        setPhoneNumberValidation({
          valid: phoneValidator(e.target.value),
          message: "Please Enter Valid Phone Number of format 01111111111",
        });
        break;
      case "address1":
        setAddressValidaton({
          valid: !!e.target?.value,
          message: "Please Enter Address Line 1",
        });
        break;
      case "city":
        setCityValidation({
          valid: !!e.target?.value,
          message: "Please Enter Valid City",
        });
        break;
      case "postCode":
        setPostalCodeValidation({
          valid: postalCodeValidator(e.target?.value),
          message: "Please Enter Valid Postal Code",
        });
        break;
      case "gender":
        setGenderValidation({
          valid: true,
          message: "",
        });
        break;
      default:
        break;
    }
  };

  const handleBirthdateDate = (date) => {
    // When Selecting Date, ignore time zone by doing start of day
    if (date) {
      date = moment([
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      ]).format("YYYY-MM-DD");
      setBirthday(date);
    }
    if (birthdateValidator(date)) {
      setBirthdateValidation({ valid: true, message: "" });
    } else {
      setBirthdateValidation({
        valid: false,
        message: "Please Enter Valid Birthdate",
      });
    }
  };

  const handleServiceMethodChange = (e) => {
    dispatch({
      type: "SET_FIELD",
      field: "serviceMethod",
      value: e.target.value,
    });
    setMethodValidation({
      valid: !!e.target.value,
      message: "Method Is Required",
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      // Get Business Hour Start:
      let startHour =
        businessStartTime && businessEndTime
          ? setHours(
              setMinutes(new Date(), 0),
              parseInt(
                getBusinessHoursForDay(moment(date).format("dddd")).start.split(
                  ":",
                )[0],
              ),
            )
          : setHours(startDate, 9);
      startHour = startHour.getHours() || 0;
      date = moment(date).hour(startHour).minute(0).toDate();
      setStartDate(date);
      handleTimeChange(date);
      setAppointmentDateValidation({ valid: true, message: "" });
      setAppointmentTimeValidation({
        valid: false,
        message: "Please Select Time",
      });
    } else {
      setAppointmentDateValidation({
        valid: false,
        message: "Please Select Date",
      });
    }
  };

  const handleTimeChange = (time) => {
    if (time) {
      let fmttime = moment(time).utc().format();
      setStartTime(time);
      setStartTimeFormatted(fmttime);
      setAppointmentTimeValidation({ valid: true, message: "" });
    } else {
      setAppointmentTimeValidation({
        valid: false,
        message: "Please Select Time",
      });
    }
  };

  const isBusinessDay = (date) => {
    const dayName = moment(date).format("dddd");
    return businessDays ? businessDays[dayName] : true;
  };

  const getBusinessHoursForDay = (dayName) => {
    return {
      start: businessStartTime ? businessStartTime[dayName] : null,
      close: businessEndTime ? businessEndTime[dayName] : null,
    };
  };

  const getMinTime = () => {
    const dayName = moment(startDate).format("dddd");
    const startHour = getBusinessHoursForDay(dayName).start || "09:00";
    return moment(startHour, "HH:mm").toDate();
  };

  const getMaxTime = () => {
    const dayName = moment(startDate).format("dddd");
    const endHour = getBusinessHoursForDay(dayName).close || "17:00";
    return moment(endHour, "HH:mm").toDate();
  };

  const consentDownload = () => {
    createPdfBlobUrl(consentForm);
  };

  const interactiveFormHandler = (id, value) => {
    const updatedData = [...interactiveFormData];
    updatedData[id] = value;
    setInteractiveFormData(updatedData);
  };

  const suitabilityFormHandler = (id, value) => {
    const updatedData = [...suitabilityFormData];
    updatedData[id] = value;
    setSuitabilityFormData(updatedData);
    setSuitabilityError(
      !updatedData.includes(true) || unSuitabilityFormData.includes(true),
    );
  };

  const unsuitabilityFormHandler = (id, value) => {
    const updatedData = [...unSuitabilityFormData];
    updatedData[id] = value;
    setUnSuitabilityFormData(updatedData);
    setSuitabilityError(
      updatedData.includes(true) || !suitabilityFormData.includes(true),
    );
  };

  const handleSubmit = () => {
    setSuitabilityError(false);
    let iFormData = [];
    if (interactiveFormData.length > 0) {
      interactiveForm.forEach((d, i) => {
        iFormData.push({ question: d.question, value: interactiveFormData[i] });
      });
    }
    if (suitabilityFormData.length > 0) {
      suitabilityForm.forEach((d, i) => {
        iFormData.push({ question: d.question, value: suitabilityFormData[i] });
      });
    }
    if (unSuitabilityFormData.length > 0) {
      unSuitabilityForm.forEach((d, i) => {
        iFormData.push({
          question: d.question,
          value: unSuitabilityFormData[i],
        });
      });
    }
    let payload = {
      ...formState,
      birthday,
      bookingDate: startDate,
      startTime: moment(startTime).utc().format(),
      endTime: moment(startTime)
        .add(data.serviceSearchData.service.srvc_duration_time, "minutes")
        .utc()
        .format(),
      interactiveFormData: iFormData,
      ...data.serviceSearchData,
      customer: customer,
    };
    payload = orderDataPayloadNew(payload);
    serviceAddToCart(payload);
    setBookingStep(3);
  };

  return (
    <div className="col-12 px-0 mt-0 pt-0">
      <div
        className="card border-0 rounded p-2 shadow-primary"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="custom-form">
          <form>
            <div className="row">
              <div className="accordion">
                {/*Appointment*/}
                <div className="accordion-item border rounded mt-2 mx-2">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={`${
                        bookingStep === 1 ? "" : "collapsed"
                      } accordion-button border-0 bg-light px-2`}
                      type="button"
                      onClick={() => {
                        bookingStep !== 1
                          ? setBookingStep(1)
                          : setBookingStep(0);
                      }}
                    >
                      Appointment
                    </button>
                  </h2>
                  {/*Form*/}
                  <div
                    id="collapseTwo"
                    className={`${
                      bookingStep === 1 ? "show" : ""
                    } accordion-collapse border-0 collapse`}
                  >
                    <div className="container">
                      <div className="row">
                        {/* Practitioner Details */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Practitioner
                            </label>
                            <div className="col-8">
                              <InputField
                                value={
                                  practitioner
                                    ? practitioner.business_nm
                                    : "Practitioner :"
                                }
                                placeholder="Practitioner :"
                                readOnly
                                ariaLabel="Practitioner"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Service Details */}
                        {service && service?.nhs_in ? (
                          <div className="col-lg-6 mb-3">
                            <div className="d-flex align-items-start">
                              <label className="col-4 fw-bold text-start">
                                Service
                              </label>
                              <div className="col-8">
                                <InputField
                                  value={
                                    service ? service.srvc_nm : "Service :"
                                  }
                                  placeholder="Service :"
                                  readOnly
                                  ariaLabel="Service"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-lg-6 mb-3">
                              <div className="d-flex align-items-start">
                                <label className="col-4 fw-bold text-start">
                                  Service
                                </label>
                                <div className="col-8">
                                  <InputField
                                    value={
                                      service ? service.srvc_nm : "Service :"
                                    }
                                    placeholder="Service :"
                                    readOnly
                                    ariaLabel="Service"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 mb-3">
                              <div className="d-flex align-items-start">
                                <label className="col-4 fw-bold text-start">
                                  Price
                                </label>
                                <div className="col-8">
                                  <InputField
                                    value={
                                      service
                                        ? `Service Cost: £ ${service.srvc_price_amt}`
                                        : null
                                    }
                                    placeholder="Service Cost: £"
                                    readOnly
                                    ariaLabel="Service Cost"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Date Picker */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Date*
                            </label>
                            <div className="col-8">
                              <DatePickerComponent
                                selectedDate={startDate}
                                onChange={handleDateChange}
                                filterDate={isBusinessDay}
                                minDate={new Date()}
                                placeholderText="Choose Appointment Date*"
                                ariaLabel="Appointment Date"
                                required={true}
                                // validation={appointmentDateValidation}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Time Picker */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Time*
                            </label>
                            <div className="col-8">
                              <TimePickerComponent
                                selectedTime={startTime}
                                onChange={handleTimeChange}
                                excludeTimes={[
                                  ...overbookedSlots,
                                  ...practitionerBlockedSlots,
                                ]}
                                minTime={getMinTime()}
                                maxTime={getMaxTime()}
                                ariaLabel="Appointment Time"
                                required={true}
                                disabled={!startDate}
                              />
                              {appointmentTimeValidation &&
                                !appointmentTimeValidation.valid && (
                                  <span
                                    className="text-danger mt-0 pt-0"
                                    style={{ fontSize: "smaller" }}
                                  >
                                    {appointmentTimeValidation.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        {/* Service Method */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Method
                            </label>
                            <div className="col-8">
                              <div className="form-control mb-3 px-0 border-0">
                                <select
                                  className="form-control"
                                  style={{ borderWidth: "medium" }}
                                  value={formState.serviceMethod}
                                  onChange={handleServiceMethodChange}
                                  aria-label="Select Service Method"
                                >
                                  <option value="">
                                    Select Service Method*
                                  </option>
                                  {serviceMethods.map((met) => (
                                    <option key={met} value={met}>
                                      {met}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {!methodValidation.valid && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "smaller" }}
                                >
                                  {methodValidation.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Notes */}
                        <div className="col-lg-12 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 col-lg-2 fw-bold text-start">
                              Notes
                            </label>
                            <div className="col-8 col-lg-10">
                              <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control"
                                style={{ borderWidth: "medium" }}
                                placeholder="Your Notes :"
                                value={formState.serviceRequestNotes}
                                onChange={handleFieldChange(
                                  "serviceRequestNotes",
                                )}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.preventDefault()
                                }
                                aria-label="Enter Notes"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        {/* Consent Form */}
                        <div className="col-lg-12">
                          <ConsentForm
                            ageConsent={ageConsent}
                            setAgeConsent={setAgeConsent}
                            consent={consent}
                            setConsent={setConsent}
                            showConsentLink={showConsentLink}
                            consentDownload={consentDownload}
                            informationConsent={informationConsent}
                            setInformationConsent={setInformationConsent}
                            showInformationLink={showInformationLink}
                            informationLink={informationLink}
                            showMultiAppointmentConsent={
                              showMultiAppointmentConsent
                            }
                            multiAppointmentConsent={multiAppointmentConsent}
                            setMultiAppointmentConsent={
                              setMultiAppointmentConsent
                            }
                          />
                          {/* Interactive Forms */}
                          {showInteractiveForm && (
                            <div>
                              <label
                                className="form-label"
                                style={{ fontWeight: "bold" }}
                              >
                                Please answer the following questions (Select
                                all that apply):
                                <span className="text-danger">*</span>
                              </label>
                              {interactiveForm.map((item, index) => (
                                <InteractiveForm
                                  key={index}
                                  question={item.question}
                                  id={index}
                                  handler={interactiveFormHandler}
                                />
                              ))}
                            </div>
                          )}
                          {/* Suitability Forms */}
                          {(showSuitabilityForm || showUnSuitabilityForm) && (
                            <div>
                              <label
                                className="form-label"
                                style={{ fontWeight: "bold" }}
                              >
                                Please answer the following questions (Select
                                all that apply):
                                <span className="text-danger">*</span>
                              </label>
                              {unSuitabilityForm.map((item, index) => (
                                <InteractiveForm
                                  key={index}
                                  question={item.question}
                                  id={index}
                                  handler={unsuitabilityFormHandler}
                                />
                              ))}
                              {suitabilityForm.map((item, index) => (
                                <InteractiveForm
                                  key={index}
                                  question={item.question}
                                  id={index}
                                  handler={suitabilityFormHandler}
                                />
                              ))}
                              {suitabilityError && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "medium" }}
                                >
                                  Based on your answers, you are ineligible for
                                  this service!
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Next Button */}
                        <div className="col-lg-12 mb-3">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-primary fw-bolder"
                              disabled={
                                !ageConsent ||
                                (showConsentLink && !consent) ||
                                (showInformationLink && !informationConsent) ||
                                !appointmentDateValidation.valid ||
                                !appointmentTimeValidation.valid ||
                                !methodValidation.valid ||
                                suitabilityError
                              }
                              onClick={() => {
                                setBookingStep(2);
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Patient*/}
                <div className="accordion-item border rounded mt-2 mx-2">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={`${
                        bookingStep === 2 ? "" : "collapsed"
                      } accordion-button border-0 bg-light px-2`}
                      type="button"
                      onClick={null}
                    >
                      Patient
                    </button>
                  </h2>
                  {/*Form*/}
                  <div
                    id="collapseTwo"
                    className={`${
                      bookingStep === 2 ? "show" : ""
                    } accordion-collapse border-0 collapse`}
                  >
                    <div className="container">
                      <div className="row">
                        {/* First Name */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              First Name
                            </label>
                            <div className="col-8">
                              <InputField
                                required
                                placeholder="Patient First Name :*"
                                value={formState.firstName}
                                onChange={handleFieldChange("firstName")}
                                validation={firstNameValidation}
                                ariaLabel="First Name"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Last Name */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Last Name
                            </label>
                            <div className="col-8">
                              <InputField
                                required
                                placeholder="Patient Last Name :*"
                                value={formState.lastName}
                                onChange={handleFieldChange("lastName")}
                                validation={lastNameValidation}
                                ariaLabel="Last Name"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Email Address */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Email Address
                            </label>
                            <div className="col-8">
                              <InputField
                                type="email"
                                required
                                placeholder="Your email :*"
                                value={formState.emailAddress}
                                onChange={handleFieldChange("emailAddress")}
                                validation={emailValidation}
                                ariaLabel="Email Address"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Phone Number */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Phone Number
                            </label>
                            <div className="col-8">
                              <InputField
                                type="tel"
                                required
                                placeholder="Your Phone* : 01223334444"
                                value={formState.phoneNumber}
                                onChange={handleFieldChange("phoneNumber")}
                                validation={phoneNumberValidation}
                                ariaLabel="Phone Number"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Birthdate */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Birthdate
                            </label>
                            <div className="col-8">
                              <DatePickerComponent
                                selectedDate={
                                  birthday
                                    ? moment(birthday, "YYYY-MM-DD").toDate()
                                    : null
                                }
                                onChange={handleBirthdateDate}
                                placeholderText="Enter Birthdate"
                                ariaLabel="Enter Birthdate"
                                required={true}
                              />
                              {birthdateValidation &&
                                !birthdateValidation.valid && (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "smaller" }}
                                  >
                                    {birthdateValidation.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        {/* Gender */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Gender
                            </label>
                            <div className="col-8">
                              <select
                                className="form-control"
                                style={{ borderWidth: "medium" }}
                                onChange={handleFieldChange("gender")}
                                value={formState.gender}
                                required={true}
                                aria-label="Select Gender"
                              >
                                <option value="" disabled={true}>
                                  Select Gender*
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* Address Line 1 */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Address Line 1
                            </label>
                            <div className="col-8">
                              <InputField
                                type="text"
                                required
                                placeholder="Your Address Line 1* :"
                                value={formState.address1}
                                onChange={handleFieldChange("address1")}
                                validation={addressValidation}
                                ariaLabel="Address Line 1"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Address Line 2 */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Address Line 2
                            </label>
                            <div className="col-8">
                              <InputField
                                type="text"
                                placeholder="Your Address Line 2 :"
                                value={formState.address2}
                                onChange={handleFieldChange("address2")}
                                ariaLabel="Address Line 2"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Postal Code */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              Postal Code
                            </label>
                            <div className="col-8">
                              <InputField
                                type="text"
                                id="autocomplete_postal"
                                required
                                placeholder="Your Postal Code* :"
                                value={formState.postCode}
                                onChange={handleFieldChange("postCode")}
                                validation={postalCodeValidation}
                                ariaLabel="Postal Code"
                              />
                            </div>
                          </div>
                        </div>

                        {/* City */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-flex align-items-start">
                            <label className="col-4 fw-bold text-start">
                              City
                            </label>
                            <div className="col-8">
                              <InputField
                                type="text"
                                id="autocomplete_city"
                                required
                                placeholder="Your City* :"
                                value={formState.city}
                                onChange={handleFieldChange("city")}
                                validation={cityValidation}
                                ariaLabel="City"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Back Button */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-secondary fw-bolder"
                              onClick={() => setBookingStep(1)}
                            >
                              Back
                            </button>
                          </div>
                        </div>

                        {/* Submit Button */}
                        <div className="col-lg-6 mb-3">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-primary fw-bolder"
                              disabled={
                                (showConsentLink && !consent) ||
                                (showInformationLink && !informationConsent) ||
                                !validForm ||
                                !formState.gender
                              }
                              onClick={handleSubmit}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Checkout*/}
                <div className="accordion-item border rounded mt-2 mx-2">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={`${
                        bookingStep === 3 ? "" : "collapsed"
                      } accordion-button border-0 bg-light px-2`}
                      type="button"
                      onClick={null}
                      // onClick={() => {
                      //   bookingStep !== 3
                      //     ? setBookingStep(3)
                      //     : setBookingStep(0);
                      // }}
                    >
                      Checkout
                    </button>
                  </h2>
                  {/*Form*/}
                  <div
                    id="collapseTwo"
                    className={`${
                      bookingStep === 3 ? "show" : ""
                    } accordion-collapse border-0 collapse`}
                  >
                    <div className="container">
                      <div className="row">
                        {/*Add Another*/}
                        <div className="col-lg-6 pb-1">
                          <div className="d-grid">
                            <Link
                              to={formatBookUrl(serviceType)}
                              className="btn btn-muted fw-bolder"
                              onClick={() => {
                                setBookingStep(1);
                                resetSelection();
                              }}
                            >
                              Add Another Appointment
                            </Link>
                          </div>
                        </div>
                        {/* Checkout */}
                        <div className="col-lg-6">
                          <div className="d-grid">
                            <Link
                              to="/shop/cart"
                              type="button"
                              className="btn btn-dark fw-bolder"
                              onClick={() => {
                                setBookingStep(1);
                              }}
                            >
                              Checkout
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentForm;
