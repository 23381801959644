// components/ConsentForm.js
import React from "react";
import { FormControlLabel, FormGroup, styled, Switch } from "@mui/material";
import { Link } from "react-router-dom";
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="0" y="16" font-family="Arial" font-size="14" font-weight="bold" fill="white">Yes</text></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="2" y="16" font-family="Arial" font-size="14" font-weight="bold" fill="white">No</text></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 17,
    height: 17,
    margin: 2,
  },
}));

const ConsentForm = ({
  ageConsent,
  setAgeConsent,
  consent,
  setConsent,
  showConsentLink,
  consentDownload,
  informationConsent,
  setInformationConsent,
  showInformationLink,
  informationLink,
  showMultiAppointmentConsent,
  multiAppointmentConsent,
  setMultiAppointmentConsent,
}) => {
  return (
    <div className="d-grid">
      {/*Multiple Appointments*/}
      {showMultiAppointmentConsent && (
        <FormGroup>
          <FormControlLabel
            className="text-primary"
            required
            control={
              <Android12Switch
                checked={multiAppointmentConsent}
                onChange={() =>
                  setMultiAppointmentConsent(!multiAppointmentConsent)
                }
              />
            }
            label={
              <Link to={null} onClick={consentDownload}>
                I understand that this service requires multiple appointments. I
                acknowledge that the service provider will schedule a follow-up
                consultation at the end of each visit.
              </Link>
            }
            style={{ fontSize: "small", textAlign: "start" }}
          />
        </FormGroup>
      )}
      {/*Age Consent*/}
      <FormGroup>
        <FormControlLabel
          required
          control={
            <Android12Switch
              checked={ageConsent}
              onChange={() => setAgeConsent(!ageConsent)}
            />
          }
          label={
            <span>
              I am 18 and above, booking my own appointment, and accept
              ZipLaneRx’s{" "}
              <Link
                to="https://www.ziplanerx.com/aboutus/terms"
                target="_blank"
                aria-label={"ZipLaneRx -- Terms"}
              >
                Terms
              </Link>
            </span>
          }
          style={{ fontSize: "small", textAlign: "start" }}
        />
      </FormGroup>
      {/*Consent Download*/}
      {showConsentLink && (
        <FormGroup>
          <FormControlLabel
            className="text-primary"
            required
            control={
              <Android12Switch
                checked={consent}
                onChange={() => setConsent(!consent)}
              />
            }
            label={
              <Link to={null} onClick={consentDownload}>
                I have reviewed and acknowledged, the service suitability.
              </Link>
            }
            style={{ fontSize: "small", textAlign: "start" }}
          />
        </FormGroup>
      )}
      {/*Information Link Consent*/}
      {showInformationLink && (
        <FormGroup>
          <FormControlLabel
            className="text-primary"
            required
            control={
              <Android12Switch
                checked={informationConsent}
                onChange={() => setInformationConsent(!informationConsent)}
              />
            }
            label={
              <Link
                to={null}
                onClick={() => window.open(informationLink, "_blank")}
                aria-label="Information"
              >
                I have reviewed and acknowledged, the service suitability.
              </Link>
            }
            style={{ fontSize: "small", textAlign: "start" }}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default ConsentForm;
