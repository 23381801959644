import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function NhsServicesTable({ services, handleServiceBook, isSearch = false }) {
  return (
    <table
      className="table mb-0 table-center table-wrap"
      style={{ fontSize: "small" }}
    >
      <thead className="text-start">
        <tr>
          <th className="border-bottom p-3" scope="col">
            Service
          </th>
          {isSearch ? (
            <th className="border-bottom p-3" scope="col">
              Service Cost
            </th>
          ) : null}
          <th className="border-bottom p-3" scope="col">
            Book
          </th>
        </tr>
      </thead>
      <tbody className="text-start">
        {services?.map((item, index) => {
          return (
            <tr>
              <td className="p-3">{item.srvc_nm.toUpperCase()}</td>
              {isSearch ? (
                <td className="p-3">{`£ ${item.srvc_price_amt}`}</td>
              ) : null}
              <td className="p-3">
                <Link
                  to=""
                  className="text-primary"
                  onClick={() => {
                    handleServiceBook(item);
                  }}
                >
                  Book <i className="uil uil-arrow-right"></i>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default NhsServicesTable;
