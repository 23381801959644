// footer component
import React, { Component } from "react";

class AboutBookAppointment extends Component {
  render() {
    return (
      <div className="col-md-6">
        <div className="me-xl-3">
          <div className="section-title mb-4 pb-2">
            <i className="uil uil-notes text-primary h2"></i>
            <h4 className="title mb-4">Book Your Appointment</h4>
            <p className="text-muted para-desc mb-0">
              We pride on making your service appointment seamless, secure, and
              reliable!
            </p>
          </div>

          <div className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden">
            <i className="uil uil-stethoscope-alt icons h2 mb-0 text-primary"></i>
            <div className="ms-3">
              <h5 className="titles">Certified Practitioners</h5>
              <p className="text-muted para mb-0">
                Our extensive network of certified practitioners are accredited,
                governed and regulated by major councils like GMC and GPhC.
              </p>
            </div>
            <div className="big-icon">
              <i className="uil uil-stethoscope-alt"></i>
            </div>
          </div>

          <div className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
            <i className="uil uil-star icons h2 mb-0 text-primary"></i>
            <div className="ms-3">
              <h5 className="titles">Highly rated by Customers like you</h5>
              <p className="text-muted para mb-0">
                Our network partners strive for quality, empathy and consistency
                of services driving high customer ratings!
              </p>
            </div>
            <div className="big-icon">
              <i className="uil uil-star"></i>
            </div>
          </div>

          <div className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
            <i className="ri ri-lightbulb-flash-line icons h2 mb-0 text-primary"></i>
            <div className="ms-3">
              <h5 className="titles">Modern frictionless experiences</h5>
              <p className="text-muted para mb-0">
                At ZipLaneRx, we pride in bringing frictionless experiences and
                telemedicine innovations to ensure each service interactions for
                our customers and practitioners are meaningful.
              </p>
            </div>
            <div className="big-icon">
              <i className="ri ri-lightbulb-flash-line"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutBookAppointment;
