import React from "react";

function TotalSummary({ deliveryFee, total }) {
  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 ms-auto mt-2 pt-2">
        <div className="table-responsive bg-white rounded shadow">
          <table className="table table-center table-padding mb-0">
            <tbody>
              {deliveryFee && deliveryFee > 0 ? (
                <tr>
                  <td className="h6 p-3">Delivery Fee</td>
                  <td className="text-center font-weight-bold p-3">
                    £ {deliveryFee}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="h6 p-3">Total</td>
                <td className="text-end font-weight-bold p-3 px-4">
                  £ {total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TotalSummary;
