import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { Helmet } from "react-helmet-async";
import { offerPatients, offerPractitioners } from "../../data/data";

export default function AboutUs() {
  // State Variables
  const [selectedPatientQuestion, setSelectedPatientQuestion] = useState(0);
  const [selectedPractitionerQuestion, setSelectedPractitionerQuestion] =
    useState(0);

  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- About Us</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/heart.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- About Us</h1>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About us
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <h2
                className="card-title border-bottom"
                style={{ fontSize: "x-large" }}
              >
                About Us:
              </h2>
              <div className="card-body mt-4">
                <p className="text-muted">
                  Welcome to ZipLaneRx, where our mission is to revolutionize
                  healthcare by fostering inclusivity, accessibility and
                  empowerment for both customers and healthcare practitioners.
                  We believe that everyone deserves quality and affordable
                  healthcare regardless of the background or location.
                </p>
                <p className="text-muted">
                  Our platform is dedicated to breaking barriers, providing a
                  space where individuals can easily connect with diverse
                  practitioners, access a range of healthcare products and
                  services, and avail those through telemedicine as well as
                  in-person experiences, contributing to a more democratized
                  healthcare experience.
                </p>
                <p className="text-muted">
                  Join us on this journey toward a healthier, more inclusive
                  future for all!
                </p>
              </div>
              <h2
                className="card-title border-bottom"
                style={{ fontSize: "x-large" }}
              >
                What we offer:
              </h2>
              <div className="card-body mt-4">
                <h3 className="fw-bolder" style={{ fontSize: "large" }}>
                  Patients:
                </h3>
                <p className="text-muted">
                  ZipLaneRx offers unparalleled convenience and accessibility
                  for NHS, and Private Pharmacy, GP and Pathology service
                  bookings.{" "}
                </p>
                <div className="accordion">
                  {Object.keys(offerPatients).map((item, index) => {
                    return (
                      <div
                        className="accordion-item border-0 rounded mt-2 mx-2"
                        key={index}
                      >
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            className={`${
                              selectedPatientQuestion === item
                                ? ""
                                : "collapsed"
                            } accordion-button border-0 bg-light px-2`}
                            type="button"
                            onClick={() => {
                              selectedPatientQuestion !== item
                                ? setSelectedPatientQuestion(item)
                                : setSelectedPatientQuestion(null);
                            }}
                          >
                            {item !== "null" ? offerPatients[item].key : null}
                          </button>
                        </h4>
                        <div
                          id="collapseTwo"
                          className={`${
                            selectedPatientQuestion === item ? "show" : ""
                          } accordion-collapse border-0 collapse`}
                        >
                          <div className="accordion-body text-muted">
                            <ul className="list p-2">
                              {offerPatients[item].data?.map((statement) => (
                                <li>{statement}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="card-body mt-4">
                <h3 className="fw-bolder" style={{ fontSize: "large" }}>
                  Practitioners:
                </h3>
                <p className="text-muted">
                  ZipLaneRx offers a versatile and secured platform with
                  sophisticated automation tools to help you learn, lean and
                  grow your business digitally.
                </p>
                <div className="accordion">
                  {Object.keys(offerPractitioners).map((item, index) => {
                    return (
                      <div
                        className="accordion-item border-0 rounded mt-2 mx-2"
                        key={index}
                      >
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            className={`${
                              selectedPractitionerQuestion === item
                                ? ""
                                : "collapsed"
                            } accordion-button border-0 bg-light px-2`}
                            type="button"
                            onClick={() => {
                              selectedPractitionerQuestion !== item
                                ? setSelectedPractitionerQuestion(item)
                                : setSelectedPractitionerQuestion(null);
                            }}
                          >
                            {item !== "null"
                              ? offerPractitioners[item].key
                              : null}
                          </button>
                        </h4>
                        <div
                          id="collapseTwo"
                          className={`${
                            selectedPractitionerQuestion === item ? "show" : ""
                          } accordion-collapse border-0 collapse`}
                        >
                          <div className="accordion-body text-muted">
                            <ul className="list p-2">
                              {offerPractitioners[item].data?.map(
                                (statement) => (
                                  <li>{statement}</li>
                                ),
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
