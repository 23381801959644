import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {
  getActiveServices,
  searchPharmacies,
} from "../../utils/pctmgmt.services";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { Rating } from "@mui/material";
import {
  getImage,
  mongoSearchPharmacies,
} from "../../utils/referencedata.services";
import { getNHSReviews } from "../../utils/proxy.services";
import { IoLocationSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import ScrollTop from "../../components/scrollTop";
import NhsServicesTable from "../../components/pharmacy/NhsServicesTable";
import PrivateServicesTable from "../../components/pharmacy/PrivateServicesTable";

export default function Pharmacy() {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // Variables
  let params = useParams();
  let entity_webstore_url = params.entity_webstore_url;
  const [pharmacy, setPharmacy] = useState(null);
  const [bannerImageId, setBannerImageId] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [hours, setHours] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [nhsMetadata, setNHSMetadata] = useState(null);
  const [activeServices, setActiveServices] = useState(null);
  const [pharmNHSServices, setPharmNHSServices] = useState(null);
  const [pharmPrivateServices, setPharmPrivateServices] = useState({});
  const [gpServices, setGpServices] = useState({});
  const [pathServices, setPathServices] = useState({});
  const [privateServicesCategory, setPrivateServicesCategory] = useState(null);
  const [servicesActiveIndex, setServicesActiveIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [servicesInFocus, setServicesInFocus] = useState([]);

  // Init: Get Pharmacy
  useEffect(() => {
    // Practitioner Query
    let payload = { entity_webstore_url: `/${entity_webstore_url}` };

    // Make Call
    searchPharmacies(payload).then((r) => {
      let res = r.data;
      // Get First Object
      if (res.length > 0) {
        setPharmacy(res[0]);
        setBannerImageId(res[0].image_url);
        getImage(res[0].image_url)
          .then((res) => {
            setBannerImage(`data:image/;base64,${res.data.image}`);
          })
          .catch((err) => {});
      }
    });
  }, [entity_webstore_url]);

  // Set Operating Hours
  useEffect(() => {
    // Get Data
    let business_days = pharmacy?.business_days_json;
    let start_hours = pharmacy?.business_hours_start_tm_json;
    let end_hours = pharmacy?.business_hours_close_tm_json;
    let h = {};

    // Set Hours
    if (business_days) {
      Object.keys(business_days).forEach((day) => {
        h[day] = business_days[day]
          ? `${start_hours[day]} - ${end_hours[day]}`
          : "Closed";
      });
      setHours(h);
    }
  }, [pharmacy]);

  // Get Google Reviews
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      let payload = {
        organization_code: pharmacy?.business_ods_nbr,
      };

      // Make Call
      mongoSearchPharmacies(payload).then((r) => {
        if (r.data) {
          setMetadata(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Get NHS Reviews
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      getNHSReviews(pharmacy?.business_ods_nbr).then((r) => {
        if (r.data) {
          setNHSMetadata(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Get Active Services
  useEffect(() => {
    // Check if Pharmacy
    if (pharmacy) {
      // Payload
      getActiveServices(pharmacy?.p_nbr).then((r) => {
        if (r.data) {
          setActiveServices(r.data);
        }
      });
    }
  }, [pharmacy]);

  // Filter and Group Services
  useEffect(() => {
    if (activeServices) {
      // NHS
      setPharmNHSServices(activeServices.filter((e) => e.nhs_in == true));

      // Pharm Private
      let pharmPriv = activeServices.filter(
        (e) => e.nhs_in == false && e.srvc_sub_category === "PHARM",
      );
      setPharmPrivateServices(groupServices(pharmPriv));

      // GP Private
      let gpPriv = activeServices.filter(
        (e) => e.nhs_in == false && e.srvc_sub_category === "GP",
      );
      setGpServices(groupServices(gpPriv));

      // Path Private
      let pathPriv = activeServices.filter(
        (e) => e.nhs_in == false && e.srvc_sub_category === "PATH",
      );
      setPathServices(groupServices(pathPriv));
    }
  }, [activeServices]);

  // Handle Search
  useEffect(() => {
    if (!searchQuery) {
      setServicesInFocus([]);
    } else {
      const searchFilteredServices = activeServices.filter((obj) =>
        obj.srvc_nm.toLowerCase().includes(searchQuery),
      );
      setServicesInFocus(searchFilteredServices);
    }
  }, [searchQuery]);

  // Group Function
  const groupServices = (services) => {
    // Group
    let grouped = services.reduce((acc, current) => {
      const key = current.srvc_type;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(current);
      return acc;
    }, {});
    return grouped;
  };

  // Handlers
  const handleServiceBook = (service) => {
    window.open(
      `${process.env.REACT_APP_frontoffice_base}/book/service?webstore_referred=true&p_nbr=${pharmacy.p_nbr}&srvc_id=${service.srvc_id}`,
    );
  };

  const handleSearch = (e) => {
    // Set Search Query
    if (!e.target.value || e.target.value === "") {
      setSearchQuery(null);
      setServicesActiveIndex(0);
    } else {
      setSearchQuery(e.target.value.toLowerCase());
    }
  };

  let settings1 = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>{`ZipLaneRx -- ${pharmacy ? pharmacy.business_nm : ""}}`}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      {/*Background Image*/}
      <section className="home-slider position-relative">
        <div
          className="bg-half-150 d-table align-items-center w-100"
          style={{
            backgroundImage: bannerImage
              ? `url(${bannerImage})`
              : 'url("assets/images/logo-icon.png")',
            backgroundPosition: "center",
          }}
        ></div>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 d-flex justify-content-center align-items-end text-center">
              <div className="heading-title text-center">
                <h1 className="fw-bold text-primary title-dark mb-0">
                  {pharmacy ? pharmacy.business_nm : ""}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <nav
          aria-label="breadcrumb"
          className="d-inline-block text-wrap mt-1 mb-0 pb-0"
        >
          <ul className="breadcrumb bg-light rounded mb-0 py-2 px-2">
            <li className="breadcrumb-item text-primary">
              <IoLocationSharp />
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_addr1.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_city.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              {pharmacy ? pharmacy.p_zip.toUpperCase() : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              <FaPhoneAlt />
              {pharmacy ? ` ${pharmacy.business_phone_nbr}` : ""}
            </li>
            <li
              className="breadcrumb-item"
              aria-current="page"
              style={{ fontSize: "small" }}
            >
              <MdOutlineMail />
              {pharmacy ? ` ${pharmacy.business_email_addr.toUpperCase()}` : ""}
            </li>
          </ul>
        </nav>
      </section>
      {/*About Us, Services, Hours*/}
      <div className="container mt-4 mb-4" id="about">
        <div className="row">
          {/*About Us*/}
          <div
            className="col-lg-3 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            {/*About Us Title*/}
            <div className="section-title mb-4 pb-2">
              <h2 className="title mb-4">About Us</h2>
              <p className="text-muted text-lg-start text-sm-center mb-0">
                {pharmacy ? pharmacy.business_about_us : ""}
              </p>
            </div>
            {/*Reviews*/}
            <div className="row">
              <div className="col-6">
                <div className="section-title mb-4 pb-2">
                  <h6 className="mb-2">Google Reviews</h6>
                  <div className="d-flex justify-content-center align-items-center mt-0 mb-lg-0">
                    <Rating
                      name="read-only"
                      value={metadata?.ratings || 0}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <p>
                    <span className="fw-bold">{metadata?.ratings}</span> average
                    based on{" "}
                    <span className="fw-bold">{metadata?.totalRatings}</span>{" "}
                    Ratings
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="section-title mb-4 pb-2">
                  <h6 className="mb-2">NHS Reviews</h6>
                  <div className="d-flex justify-content-center align-items-center mt-0 mb-lg-0">
                    <Rating
                      name="read-only"
                      value={nhsMetadata}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <Link
                    to={`${pharmacy?.business_website}/ratings-and-reviews`}
                    target="_blank"
                  >
                    <span className="fw-bold">Read all NHS Reviews</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Services*/}
          <div
            className="col-lg-7 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            <div className="container mt-0 mb-0" id="services">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-start mb-0 pb-2">
                    <h4 className="title mb-4 text-center">Our Services</h4>
                    <p className="text-muted mx-auto para-desc mb-0">
                      We pride on making your service appointment seamless,
                      secure, and reliable!
                    </p>
                  </div>
                  {/*Search*/}
                  <input
                    name="search"
                    id="search"
                    type="text"
                    className="form-control border-0"
                    placeholder="Search:"
                    value={searchQuery}
                    contentEditable={true}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-12 mt-0 pt-0">
                  <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mt-3 mb-0">
                    {/*Pharmacy NHS*/}
                    <li className="nav-item">
                      <Link
                        className={`${
                          !searchQuery && servicesActiveIndex === 0
                            ? "active"
                            : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => {
                          setServicesActiveIndex(0);
                          setSearchQuery(null);
                        }}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">NHS Funded</h6>
                        </div>
                      </Link>
                    </li>
                    {/*Pharmacy Private*/}
                    <li className="nav-item">
                      <Link
                        className={`${
                          !searchQuery && servicesActiveIndex === 1
                            ? "active"
                            : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => {
                          setServicesActiveIndex(1);
                          setSearchQuery(null);
                        }}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Pharmacy</h6>
                        </div>
                      </Link>
                    </li>
                    {/*GP Private*/}
                    <li className="nav-item">
                      <Link
                        className={`${
                          !searchQuery && servicesActiveIndex === 2
                            ? "active"
                            : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => {
                          setServicesActiveIndex(2);
                          setSearchQuery(null);
                        }}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">GP</h6>
                        </div>
                      </Link>
                    </li>
                    {/*Path Private*/}
                    <li className="nav-item">
                      <Link
                        className={`${
                          !searchQuery && servicesActiveIndex === 3
                            ? "active"
                            : ""
                        } nav-link rounded-0`}
                        to="#"
                        onClick={() => {
                          setServicesActiveIndex(3);
                          setSearchQuery(null);
                        }}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Pathology</h6>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <div
                    className="col-12"
                    style={{
                      height: "250pt",
                      boxSizing: "border-box",
                      margin: "0",
                      overflowY: "auto",
                      contentVisibility: "auto",
                      overflowMargin: "content-box",
                    }}
                  >
                    <div className="tab-pane fade show active shadow rounded py-4 px-0">
                      <div className="table-responsive bg-white shadow rounded mb-0">
                        {searchQuery && servicesInFocus.length > 0 ? (
                          <NhsServicesTable
                            services={servicesInFocus}
                            handleServiceBook={handleServiceBook}
                            isSearch={true}
                          />
                        ) : null}
                        {!searchQuery && servicesActiveIndex === 0 ? (
                          <NhsServicesTable
                            services={pharmNHSServices}
                            handleServiceBook={handleServiceBook}
                          />
                        ) : null}
                        {!searchQuery && servicesActiveIndex === 1 ? (
                          <PrivateServicesTable
                            services={pharmPrivateServices}
                            privateServicesCategory={privateServicesCategory}
                            setPrivateServicesCategory={
                              setPrivateServicesCategory
                            }
                            handleServiceBook={handleServiceBook}
                          />
                        ) : null}
                        {!searchQuery && servicesActiveIndex === 2 ? (
                          <PrivateServicesTable
                            services={gpServices}
                            privateServicesCategory={privateServicesCategory}
                            setPrivateServicesCategory={
                              setPrivateServicesCategory
                            }
                            handleServiceBook={handleServiceBook}
                          />
                        ) : null}
                        {!searchQuery && servicesActiveIndex === 3 ? (
                          <PrivateServicesTable
                            services={pathServices}
                            privateServicesCategory={privateServicesCategory}
                            setPrivateServicesCategory={
                              setPrivateServicesCategory
                            }
                            handleServiceBook={handleServiceBook}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Hours*/}
          <div
            className="col-lg-2 col-sm-12"
            style={{
              backgroundColor: "#f0f0f0",
              boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)" /* Adds depth */,
            }}
          >
            <div className="section-title mb-0 pb-2">
              <h2 className="title mb-4">Our Hours</h2>
              <div className="flex flex-col my-2 text-lg-start text-sm-center">
                <div className="my-2">
                  <div className="fw-bold">Monday</div>
                  <div className="text-secondary text-md">{hours?.Monday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Tuesday</div>
                  <div className="text-secondary text-md">{hours?.Tuesday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Wednesday</div>
                  <div className="text-secondary text-md">
                    {hours?.Wednesday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Thursday</div>
                  <div className="text-secondary text-md">
                    {hours?.Thursday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Friday</div>
                  <div className="text-secondary text-md">{hours?.Friday}</div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Saturday</div>
                  <div className="text-secondary text-md">
                    {hours?.Saturday}
                  </div>
                </div>
                <div className="my-2">
                  <div className="fw-bold">Sunday</div>
                  <div className="text-secondary text-md">{hours?.Sunday}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Customer Reviews*/}
      <div className="container mt-4 mb-4">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4">
                See what our customers have to say about us
              </h4>
            </div>
            <div className="client-review-slider">
              {metadata && metadata.reviews ? (
                <TinySlider settings={settings1}>
                  {metadata?.reviews.map((item, index) => {
                    return (
                      <div className="tiny-slide text-center" key={index}>
                        <p className="text-muted fw-normal fst-italic">
                          {item.text}
                        </p>
                        <img
                          src={item.profile_photo_url}
                          className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3"
                          alt={`ZipLaneRx - ${
                            pharmacy ? pharmacy.business_nm : ""
                          }`}
                        />
                        <div style={{ fontSize: "small" }}>
                          <Rating
                            name="read-only"
                            value={item.rating || 0}
                            precision={0.5}
                            readOnly
                          />
                        </div>
                        <h6 className="text-primary">{item.author_name} </h6>
                        <div style={{ fontSize: "small" }}>
                          <h6 className="text-primary">
                            <small className="text-muted">
                              {item.relative_time_description}
                            </small>
                          </h6>
                        </div>
                      </div>
                    );
                  })}
                </TinySlider>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
