import TinySlider from "tiny-slider-react";
import React from "react";
import { Link } from "react-router-dom";

function ZlrxSlider({ data }) {
  // Settings
  let settings1 = {
    container: ".client-review-slider",
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 8,
  };
  return (
    <div className="col-lg-10 text-center">
      {data ? (
        <TinySlider settings={settings1}>
          {data?.map((item, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5 mb-0"
            >
              <div className="card features feature-bg-primary bg-transparent card flex-column p-2 rounded-md shadow position-sticky overflow-hidden text-white">
                <div className="card-body p-0 mt-0">
                  <i className={`${item.logo} text-white h3 mb-0`}></i>
                  <Link to={null} className="title text-white h5">
                    {item.title}
                  </Link>
                  <p className="text-white mt-3">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </TinySlider>
      ) : null}
    </div>
  );
}

export default ZlrxSlider;
