import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { finalizePrivateAppointments } from "../../utils/sales.service";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const PaymentForm = ({ data, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showButton, setShowButton] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements) return;
    setShowButton(false);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      setShowButton(true);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_frontoffice_base}/book/services/invoice?bookingId=${data.serviceOrderData.bookingId}`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setShowButton(true);
    } else {
      finalizePrivateAppointments({
        privateServicesPaymentIntents: data.privateServicesPaymentIntents,
        serviceOrderData: data.serviceOrderData,
      }).then(() => {});
    }
  };

  return (
    <div className="row">
      <div
        className="text-start px-2"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <h5>Payment</h5>
      </div>
      <form style={{ marginTop: 0 }} onSubmit={handleSubmit}>
        <PaymentElement />
        {showButton ? (
          <div className="text-end">
            <Button
              className="btn btn-primary"
              type="submit"
              disabled={!stripe || !elements}
              style={{ minWidth: "fit-content", marginTop: 20 }}
            >
              Submit
            </Button>
          </div>
        ) : (
          <div className="text-end">
            <Button
              className="btn btn-primary"
              style={{ minWidth: "fit-content" }}
              disabled
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait
            </Button>
          </div>
        )}

        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </form>
    </div>
  );
};

export default PaymentForm;
