// two-field-search
import React from "react";
import { Link } from "react-router-dom";
import { RiHome2Line, RiPrinterLine } from "react-icons/ri";
import moment from "moment";
import { capitalize } from "@mui/material";

const ServiceInvoice = ({ data, bookingId, paymentIntentId }) => {
  return (
    <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 rounded shadow px-4 py-5">
              <div className="row-mb-0 text-start">
                <div className="col px-0 mx-0">
                  <img
                    src={"assets/images/logo-icon.png"}
                    height="75"
                    alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8 col-md-6 text-start">
                  <h5 className="mt-0 pt-0">ZiplaneRx</h5>
                  <h6 className="mt-1 pt-2">Invoice</h6>
                  <small className="text-black mb-0">
                    <small className="mb-0 text-muted">Invoice No. : </small>
                    {bookingId}
                    <br />
                    <small className="mb-0 text-muted">Invoice Dt. : </small>
                    {moment(
                      data.appointments[0].appointment_booked_dttm,
                    ).format("MMM Do YYYY")}
                    <br />
                    <small className="mb-0 text-muted">Name : </small>
                    {capitalize(data.payeeCustomer.customer_name)}
                    <br />
                    <small className="mb-0 text-muted">Email : </small>
                    {data.payeeCustomer.customer_email}
                    <br />
                    <small className="mb-0 text-muted">Phone : </small>
                    {data.payeeCustomer.customer_phone}
                  </small>
                </div>
              </div>

              <div className="pt-2 border-top">
                <div className="invoice-table pb-4">
                  <div className="table-responsive shadow rounded mt-4">
                    <table className="table table-center invoice-tb mb-0">
                      <thead>
                        <tr
                          className="text-start"
                          style={{ fontSize: "small" }}
                        >
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Practitioner
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Location
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Phone
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Service
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Time
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom"
                            style={{ minWidth: "fit-content" }}
                          >
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.appointments.map((appt, index) => {
                          return (
                            <tr
                              className="text-start"
                              style={{ fontSize: "small" }}
                            >
                              <td className="text-start">
                                {
                                  appt.appointment_metadata.organization
                                    .organizationName
                                }
                              </td>
                              <td className="text-start">{`${appt.appointment_metadata.organization.address1}, ${appt.appointment_metadata.organization.city}, ${appt.appointment_metadata.organization.postCode}`}</td>
                              <td className="text-start">
                                {appt.appointment_metadata.organization.phone}
                              </td>
                              <td className="text-start">
                                {appt.appointment_metadata.organization.email}
                              </td>
                              <td className="text-start">{`${appt.appointment_metadata.firstName} ${appt.appointment_metadata.lastName}`}</td>
                              <td className="text-start">{appt.srvc_nm}</td>
                              <td className="text-start">
                                {moment(appt.appointment_start_dttm).format(
                                  "MMM Do YYYY",
                                )}
                              </td>
                              <td className="text-start">
                                {moment(appt.appointment_start_dttm).format(
                                  "HH:mm",
                                )}
                              </td>
                              <td className="text-start">
                                £{appt.srvc_ttl_amt}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-5 ms-auto">
                      <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                          Subtotal :<span>{data.total ? data.total : 0}</span>
                        </li>
                        {/*<li className="text-muted d-flex justify-content-between pe-3 ms-0">*/}
                        {/*  Vat % :<span> 0</span>*/}
                        {/*</li>*/}
                        {/*<li className="text-muted d-flex justify-content-between pe-3 ms-0">*/}
                        {/*  Discount % :<span> 0</span>*/}
                        {/*</li>*/}
                        {/*<li className="text-muted d-flex justify-content-between pe-3 ms-0">*/}
                        {/*  Taxes :<span> 0</span>*/}
                        {/*</li>*/}
                        <li className="d-flex justify-content-between pe-3 ms-0">
                          Total :<span>{data.total ? data.total : 0}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="border-top pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="text-sm-start text-muted text-center">
                        <small className="mb-0">
                          Customer Service :{" "}
                          <Link
                            to="tel:+152534-468-854"
                            className="text-warning"
                          >
                            contact@ziplanerx.com
                          </Link>
                        </small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="text-sm-end text-muted text-center">
                        <small className="mb-0">
                          <Link to="/aboutus/terms" className="text-primary">
                            Terms & Conditions
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-4 pt-2">
              <Link
                to="/home"
                onClick={() => window.open("/home", "_self")}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiHome2Line /> Home
              </Link>
              <Link
                to="#"
                onClick={() => window.print()}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiPrinterLine /> Print
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceInvoice;
