import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function PrivateServicesTable({
  services,
  privateServicesCategory,
  setPrivateServicesCategory,
  handleServiceBook,
}) {
  return (
    <div className="accordion">
      {Object.keys(services).map((item, index) => {
        return (
          <div className="accordion-item border rounded mt-2 mx-2" key={index}>
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={`${
                  privateServicesCategory === item ? "" : "collapsed"
                } accordion-button border-0 bg-light px-2`}
                type="button"
                onClick={() => {
                  privateServicesCategory !== item
                    ? setPrivateServicesCategory(item)
                    : setPrivateServicesCategory(null);
                }}
              >
                {item !== "null" ? item : "Other"}
              </button>
            </h2>
            <div
              id="collapseTwo"
              className={`${
                privateServicesCategory === item ? "show" : ""
              } accordion-collapse border-0 collapse`}
            >
              <div className="accordion-body text-muted">
                <table
                  className="table mb-0 table-center table-wrap"
                  style={{ fontSize: "small" }}
                >
                  <thead className="text-start">
                    <tr>
                      <th className="border-bottom p-3" scope="col">
                        Service
                      </th>

                      <th className="border-bottom p-3" scope="col">
                        Service Cost
                      </th>

                      <th className="border-bottom p-3" scope="col">
                        Book
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {services[item].map((i, index) => {
                      return (
                        <tr>
                          <td className="p-3">{i.srvc_nm.toUpperCase()}</td>

                          <td className="p-3">{`£ ${i.srvc_price_amt}`}</td>

                          <td className="p-3">
                            <Link
                              to=""
                              className="text-primary"
                              onClick={() => {
                                handleServiceBook(i);
                              }}
                            >
                              Book <i className="uil uil-arrow-right"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PrivateServicesTable;
