import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import "tiny-slider/dist/tiny-slider.css";
import Header from "../../components/header/header";
import moment from "moment";
import ServiceInvoice from "../../components/invoice/service-invoice";
import {
  finalizePrivateAppointments,
  getInvoiceData,
} from "../../utils/sales.service";

export default function ServiceInvoicePage() {
  // Variables
  const [searchParams, setSearchParams] = useSearchParams();
  let bookingId = searchParams.get("bookingId");
  let paymentIntentId = searchParams.get("payment_intent") || null;
  const [invoiceData, setInvoiceData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  // Use Effect

  useEffect(() => {
    // Search Appointments
    if (bookingId && !paymentIntentId) {
      // Search in MySql
      getInvoiceData(bookingId)
        .then((res) => {
          if (res) {
            setInvoiceData(res);
            setShowInvoice(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [bookingId, paymentIntentId]);

  useEffect(() => {
    // Search Appointments
    if (bookingId && paymentIntentId) {
      // Finalize Private
      finalizePrivateAppointments({ bookingId, paymentIntentId }).then((r) => {
        if (r) {
          // Search in MySql
          getInvoiceData(bookingId)
            .then((res) => {
              if (res) {
                setInvoiceData(res);
                setShowInvoice(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  }, [bookingId, paymentIntentId]);

  return (
    <Fragment>
      <Header />
      <section className="bg-half-170 d-table w-100">
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "#202942" }}
        >
          <div className="container">
            <h1 style={{ opacity: "0" }}>ZipLaneRx -- Invoice</h1>
            <div className="row mt-3 justify-content-center">
              <div className="col-12">
                <div className="section-title text-center">
                  <h3
                    className="sub-title mb-4 text-light"
                    style={{ marginTop: 100, opacity: 0.7 }}
                  >
                    {invoiceData?.payeeCustomer
                      ? `Here is your Invoice ${invoiceData.payeeCustomer.customer_name}!`
                      : "Here is your Invoice!"}
                  </h3>
                  <p className="para-desc mx-auto text-white">via ZipLaneRx</p>

                  <ul className="list-unstyled mt-4">
                    <li className="list-inline-item date text-white">
                      <i className="mdi mdi-calendar-check"></i>{" "}
                      {moment(new Date()).format("MMM Do, YYYY")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {showInvoice && invoiceData ? (
                <ServiceInvoice
                  data={invoiceData}
                  bookingId={bookingId}
                  paymentIntentId={paymentIntentId}
                  id="invoice"
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
