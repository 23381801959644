import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CounterTwo from "../../components/counter";
import "tiny-slider/dist/tiny-slider.css";

import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { getProductById } from "../../utils/referencedata.services";
import { RiShoppingCartFill } from "react-icons/ri";
import { Helmet } from "react-helmet-async";

export default function PharmacyProductDetail({ addToCart }) {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // Variables
  let shop1 = "assets/images/pharmacy/shop/01.jpg";
  let shop2 = "assets/images/pharmacy/shop/02.jpg";
  let shop3 = "assets/images/pharmacy/shop/03.jpg";
  let shop4 = "assets/images/pharmacy/shop/04.jpg";
  let productImage = [shop1, shop2, shop3, shop4];
  // Variables
  let params = useParams();
  let productId = params.productId;
  let [product, setProduct] = useState({});
  let [productCount, setProductCount] = useState(0);

  // Use Effect
  useEffect(() => {
    getProductById(productId).then((response) => {
      if (response.data) {
        setProduct(response.data);
      }
    });
  }, [productId]);

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>{`ZipLaneRx -- Shop Curated Wellness Products`}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>

      <Header />

      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundColor: "#202842" }}
      >
        <h1 style={{ opacity: "0" }}>
          ZipLaneRx -- Shop Curated Wellness Products
        </h1>
        <nav aria-label="breadcrumb" className="d-inline-block mt-3">
          <ul className="breadcrumb bg-light rounded mb-0 bg-transparent">
            <li className="breadcrumb-item">
              <Link to="/">ZipLaneRx</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/pharmacy">Pharmacy</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Product Detail
            </li>
          </ul>
        </nav>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            {product?.image ? (
              <div className="col-md-5">
                <img
                  src={`data:image/gif;base64,${product.image}`}
                  className="img-fluid rounded"
                  alt={`ZipLaneRx -- ${
                    product?.labels_fulldescription
                      ? product.labels_fulldescription
                      : ""
                  }`}
                />
              </div>
            ) : (
              <div className="col-md-5">
                {/*<div className="client-review-slider">*/}
                {/*  <TinySlider settings={settings2}>*/}
                {/*    {productImage.map((item, index) => {*/}
                {/*      return (*/}
                {/*        <div className="tiny-slide" key={index}>*/}
                {/*          <img*/}
                {/*            src={item}*/}
                {/*            className="img-fluid rounded"*/}
                {/*            alt=""*/}
                {/*          />*/}
                {/*        </div>*/}
                {/*      );*/}
                {/*    })}*/}
                {/*  </TinySlider>*/}
                {/*</div>*/}
              </div>
            )}

            <div className="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-md-4">
                <h4 className="title">
                  {product?.labels_fulldescription
                    ? product.labels_fulldescription
                    : ""}
                </h4>
                <h5 className="text-muted">
                  {product?.price_trade ? `£ ${product.price_trade}` : ""}
                </h5>
                {/*<ul className="list-unstyled text-warning h5 mb-0">*/}
                {/*  <li className="list-inline-item">*/}
                {/*    <i className="mdi mdi-star"></i>*/}
                {/*  </li>*/}
                {/*  <li className="list-inline-item">*/}
                {/*    <i className="mdi mdi-star"></i>*/}
                {/*  </li>*/}
                {/*  <li className="list-inline-item">*/}
                {/*    <i className="mdi mdi-star"></i>*/}
                {/*  </li>*/}
                {/*  <li className="list-inline-item">*/}
                {/*    <i className="mdi mdi-star"></i>*/}
                {/*  </li>*/}
                {/*  <li className="list-inline-item">*/}
                {/*    <i className="mdi mdi-star"></i>*/}
                {/*  </li>*/}
                {/*  <li className="list-inline-item me-2 h6 text-muted">*/}
                {/*    (20 Rating)*/}
                {/*  </li>*/}
                {/*</ul>*/}

                {product.ingredients && product.ingredients != "NaN" ? (
                  <div>
                    <h5 className="mt-4 py-2">Overview :</h5>
                    <p className="text-muted">{product.ingredients}</p>
                  </div>
                ) : null}

                <div className="d-flex shop-list align-items-center justify-content-center">
                  <h6 className="mb-0" style={{ marginRight: 10 }}>
                    Quantity:
                  </h6>{" "}
                  <CounterTwo setProductCount={setProductCount} />
                  <Link
                    to="#"
                    className="btn btn-soft-primary ms-2"
                    onClick={() => {
                      addToCart(productId, product, productCount);
                      console.log("hi");
                      console.log(productCount);
                    }}
                  >
                    Add to Cart
                  </Link>
                  <Link
                    to="/shop/cart"
                    className="btn btn-soft-primary ms-2 btn-icon"
                  >
                    <RiShoppingCartFill />
                  </Link>
                </div>

                <div className="mt-4 pt-2">
                  {/*<Link to="#" className="btn btn-primary">*/}
                  {/*  Shop Now*/}
                  {/*</Link>*/}
                  {/*<Link*/}
                  {/*  to="#"*/}
                  {/*  className="btn btn-soft-primary ms-2"*/}
                  {/*  onClick={() => {*/}
                  {/*    addToCart(productId, product, productCount);*/}
                  {/*    console.log("hi");*/}
                  {/*    console.log(productCount);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Add to Cart*/}
                  {/*</Link>*/}
                  {/*<Link*/}
                  {/*  to="/shop/cart"*/}
                  {/*  className="btn btn-soft-primary ms-2 btn-icon"*/}
                  {/*>*/}
                  {/*  <RiShoppingCartFill />*/}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="container mt-100 mt-60">*/}
        {/*  <div className="row">*/}
        {/*    <div className="col-12">*/}
        {/*      <h5 className="mb-0">Related Products:</h5>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="row">*/}
        {/*    <div className="col-lg-12 mt-4 pt-2">*/}
        {/*      <div className="slider-range-four">*/}
        {/*        <TinySlider settings={settings}>*/}
        {/*          {productData.map((item, index) => {*/}
        {/*            return (*/}
        {/*              <div className="tiny-slide" key={index}>*/}
        {/*                <div className="card shop-list border-0">*/}
        {/*                  <ul className="label list-unstyled mb-0">*/}
        {/*                    <li>*/}
        {/*                      <Link*/}
        {/*                        to="#"*/}
        {/*                        className="badge rounded-pill badge-success"*/}
        {/*                      >*/}
        {/*                        Featured*/}
        {/*                      </Link>*/}
        {/*                    </li>*/}
        {/*                  </ul>*/}
        {/*                  <div className="shop-image position-relative overflow-hidden">*/}
        {/*                    <Link to="pharmacy-product-detail.html">*/}
        {/*                      <img*/}
        {/*                        src={item.image}*/}
        {/*                        className="img-fluid"*/}
        {/*                        alt=""*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <ul className="list-unstyled shop-icons">*/}
        {/*                      <li>*/}
        {/*                        <Link*/}
        {/*                          to="#"*/}
        {/*                          className="btn btn-icon btn-pills btn-soft-danger"*/}
        {/*                        >*/}
        {/*                          <FiHeart className="icons" />*/}
        {/*                        </Link>*/}
        {/*                      </li>*/}
        {/*                      <li className="mt-2">*/}
        {/*                        <Link*/}
        {/*                          to={`/pharmacy-product-detail/${item.id}`}*/}
        {/*                          className="btn btn-icon btn-pills btn-soft-primary"*/}
        {/*                        >*/}
        {/*                          <FiEye className="icons" />*/}
        {/*                        </Link>*/}
        {/*                      </li>*/}
        {/*                      <li className="mt-2">*/}
        {/*                        <Link*/}
        {/*                          to="/pharmacy-shop-cart"*/}
        {/*                          className="btn btn-icon btn-pills btn-soft-warning"*/}
        {/*                        >*/}
        {/*                          <FiShoppingCart className="icons" />*/}
        {/*                        </Link>*/}
        {/*                      </li>*/}
        {/*                    </ul>*/}

        {/*                    <Counter />*/}
        {/*                  </div>*/}
        {/*                  <div className="card-body content pt-4 p-2">*/}
        {/*                    <Link*/}
        {/*                      to="pharmacy-product-detail.html"*/}
        {/*                      className="text-dark product-name h6"*/}
        {/*                    >*/}
        {/*                      Thermometer*/}
        {/*                    </Link>*/}
        {/*                    <div className="d-flex justify-content-between mt-1">*/}
        {/*                      <h6 className="text-muted small font-italic mb-0 mt-1">*/}
        {/*                        $16.00{" "}*/}
        {/*                      </h6>*/}
        {/*                      <ul className="list-unstyled text-warning mb-0">*/}
        {/*                        <li className="list-inline-item">*/}
        {/*                          <i className="mdi mdi-star"></i>*/}
        {/*                        </li>*/}
        {/*                        <li className="list-inline-item">*/}
        {/*                          <i className="mdi mdi-star"></i>*/}
        {/*                        </li>*/}
        {/*                        <li className="list-inline-item">*/}
        {/*                          <i className="mdi mdi-star"></i>*/}
        {/*                        </li>*/}
        {/*                        <li className="list-inline-item">*/}
        {/*                          <i className="mdi mdi-star"></i>*/}
        {/*                        </li>*/}
        {/*                        <li className="list-inline-item">*/}
        {/*                          <i className="mdi mdi-star"></i>*/}
        {/*                        </li>*/}
        {/*                      </ul>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            );*/}
        {/*          })}*/}
        {/*        </TinySlider>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
