import React from "react";

const logos = [
  {
    src: "assets/images/ServiceLogos/NHS.png",
    title: "NHS Services",
  },
  {
    src: "assets/images/ServiceLogos/AestheticClinic.png",
    title: "Aesthetic Clinic",
  },
  {
    src: "assets/images/ServiceLogos/BioChemistryScreening.png",
    title: "Bio-Chemistry Screening",
  },
  {
    src: "assets/images/ServiceLogos/Contraception.png",
    title: "Contraception",
  },
  {
    src: "assets/images/ServiceLogos/EndocrynologyScreening.png",
    title: "Endocrynology Screening",
  },
  {
    src: "assets/images/ServiceLogos/GeneralHealth.png",
    title: "General Health",
  },
  {
    src: "assets/images/ServiceLogos/GeneralHealthScreening.png",
    title: "General Health Screening",
  },
  {
    src: "assets/images/ServiceLogos/GeneticHealthScreening.png",
    title: "Genetic Health Screening",
  },
  {
    src: "assets/images/ServiceLogos/HealthScreening.png",
    title: "Health Screening",
  },
  {
    src: "assets/images/ServiceLogos/HealthyLiving.png",
    title: "Healthy Living",
  },
  {
    src: "assets/images/ServiceLogos/HematologyScreening.png",
    title: "Hematology Screening",
  },
  {
    src: "assets/images/ServiceLogos/InfectionManagment.png",
    title: "Infection Management",
  },
  {
    src: "assets/images/ServiceLogos/LifestyleAndNutritionScreening.png",
    title: "Lifestyle And Nutrition Screening",
  },
  {
    src: "assets/images/ServiceLogos/MedicalWeightLossClinic.png",
    title: "Medical Weight Loss Clinic",
  },
  {
    src: "assets/images/ServiceLogos/MicrobiologyScreening.png",
    title: "Microbiology Screening",
  },
  {
    src: "assets/images/ServiceLogos/PainManagement.png",
    title: "Pain Management",
  },
  {
    src: "assets/images/ServiceLogos/SexualHealth.png",
    title: "Sexual Health",
  },
  {
    src: "assets/images/ServiceLogos/SexualHealthScreening.webp",
    title: "Sexual Health Screening",
  },
  {
    src: "assets/images/ServiceLogos/SkinTreatment.png",
    title: "Skin Treatment",
  },
  {
    src: "assets/images/ServiceLogos/TravelHealth.png",
    title: "Travel Health",
  },
  {
    src: "assets/images/ServiceLogos/Vaccination.png",
    title: "Vaccination",
  },
];

function LogoCarousel({ data }) {
  const slideTrackStyle = {
    display: "flex",
    width: `${250 * logos.length * 2}px`, // Adjust width dynamically
    animation: "scroll 40s linear infinite",
    marginTop: "0",
    paddingTop: "0",
  };

  const slideStyle = {
    flex: "0 0 250px",
    height: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const logoStyle = {
    width: "50px",
    height: "50px",
    marginBottom: "10px",
    filter: "grayscale(200%)",
  };

  const sliderStyle = {
    overflow: "hidden",
    width: "1140px",
    position: "relative",
    margin: "auto",
  };
  return (
    <div className="mt-0 pt-0" style={sliderStyle}>
      <div className="pt-0 mt-0" style={slideTrackStyle}>
        {/* Duplicate the logo list to create the infinite loop effect */}
        {[...logos, ...logos].map((logo, index) => (
          <div key={index} style={slideStyle} className="text-center">
            <img src={logo.src} alt={logo.title} style={logoStyle} />
            <p className="fw-normal text-primary">{logo.title}</p>
          </div>
        ))}
      </div>
      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(calc(-250px * ${logos.length}));
            }
          }
        `}
      </style>
    </div>
  );
}

export default LogoCarousel;
