// components/TimePickerComponent.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fields.css";

const TimePickerComponent = ({
  selectedTime,
  onChange,
  excludeTimes,
  minTime,
  maxTime,
  ariaLabel = null,
  required = false,
  disabled = false,
}) => (
  <div
    className="form-control mb-3 rounded bg-soft-dark"
    style={{ borderWidth: "medium" }}
  >
    <DatePicker
      className="rounded border-0 react-datepicker-time__input-container"
      placeholderText="Choose Time*"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
      selected={selectedTime ? selectedTime : minTime}
      onChange={onChange}
      excludeTimes={excludeTimes}
      minTime={minTime}
      maxTime={maxTime}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-required={required}
    />
  </div>
);

export default TimePickerComponent;
