import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";
import HeartRateCalculatorForm from "../../components/forms/calculators/heart-rate-calculator";
import { Helmet } from "react-helmet-async";

export default function Heart() {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let res = calculate(data);
      // Set Results
      setResults(res);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate
  function calculate(data) {
    if (data && parseInt(data.age) >= 0) {
      let age = parseInt(data.age);
      return {
        max: 220 - age,
        target: Math.round((220 - age) * 0.85),
      };
    } else {
      return {
        max: 0,
        target: 0,
      };
    }
  }

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- Heart Rate Calculator</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/healthyliving/heart.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- Heart Rate Calculator</h1>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <HeartRateCalculatorForm handleData={handleData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h4>
                    Ideal Heart Rate:{" "}
                    <span className="text-danger">{results.target}</span> beats
                    / minute
                  </h4>
                  <h4>
                    Max Heart Rate:{" "}
                    <span className="text-danger">{results.max}</span> beats /
                    minute
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Heart Rate Calculator</h3>
                  {/*<h5 className="card-title">What is BMI?</h5>*/}
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      This calculator will determine your maximum heart rate
                      (MHR) and calculate your exercise target heart rate (THR)
                      zones in beats per minute (bpm). Target heart rate (THR)
                      zones can be calculated from your age, age and measured
                      resting heart rate (RHR) or, measured maximum heart rate
                      (MHR) and measured resting heart rate (RHR). These
                      calculations are for adults older than 19 years old.
                    </p>
                  </ul>

                  <h5 className="card-title">Key Formulas:</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Max Heart Rate Equation</span>
                        : MHR = 220 - Age
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">
                          Target/Ideal Heart Rate Formula (Basic)
                        </span>
                        : THR = MHR * %Intensity
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Karvonen Formula</span>: THR =
                        [(MHR - RHR) x %Intensity] + RHR
                      </li>
                    </p>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
