export function orderDataPayload(data) {
  // Return Payload
  return {
    organisationId: data.practitionerData.organization_id,
    organisationCode: data.practitionerData.parent_ods_nbr,
    organisationType: "Pharmacy",
    subType: "Community Pharmacy",
    organisationStatus: "Visible",
    isPimsManaged: data.practitionerData.pims_managed_in,
    isEPSEnabled: data.practitionerData.eps_managed_in,
    organisationName: data.practitionerData.business_nm,
    address1: data.practitionerData.p_addr1,
    address2: data.practitionerData.p_addr2,
    address3: data.practitionerData.p_addr3,
    city: data.practitionerData.p_city,
    county: data.practitionerData.p_country,
    postCode: data.practitionerData.p_zip,
    latitude: data.practitionerData.business_lat,
    longitude: data.practitionerData.business_long,
    parentODSCode: data.practitionerData.parent_ods_nbr,
    parentName: data.practitionerData.parent_business_nm,
    phone: data.practitionerData.business_phone_nbr,
    email: data.practitionerData.business_email_addr,
    website: data.practitionerData.business_website,
    fax: "",
    organisationImagePath: "",
    googleReviews: data.practitionerDataMongo?.reviews,
    orgProfileName: data.practitionerData.business_nm,
    vatNumber: data.practitionerData.vat_nbr,
    serviceId: data.serviceId,
    serviceName: data.service.nhs_in
      ? `${data.serviceName} (NHS Funded)`
      : data.serviceName,
    serviceSlotTime: data.service.srvc_duration_time,
    serviceCost: data.service.nhs_in
      ? `${data.service.srvc_nhs_claims_amt}`
      : `${data.service.srvc_price_amt}`,
    isChargeable: data.service.chargeable_in,
    currencySymbol: "£",
    serviceDescription: data.service.nhs_in
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    appointmentType: data.serviceMethod,
    orderStatus: "Booked",
    serviceRequestNotes: data.serviceRequestNotes,
    interactiveFormData: data.interactiveFormData,
    fundingType: data.service.nhs_in ? "nhs" : "chargeable",
    deliveryMethod: [data.serviceMethod],
    calendarId: "1a470c8e-40ed-4c2d-b590-a4f1f6ead6cc",
    title: data.service.nhs_in
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    description: data.service.nhs_in
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    start: data.startTime,
    end: data.endTime,
    duration: data.service.srvc_duration_time,
    allDay: false,
    recurrence: null,
    isSlotConfirmed: false,
    firstName: data.firstName,
    lastName: data.lastName,
    emailId: data.emailAddress,
    phoneNumber: data.phoneNumber,
    customer: data.customer,
  };
}

export function orderDataPayloadNew(data) {
  // Return Payload
  return {
    organisationId: data.practitionerData.organization_id,
    organisationCode: data.practitionerData.parent_ods_nbr,
    p_nbr: data.practitionerData.p_nbr,
    organisationName: data.practitionerData.business_nm,
    phone: data.practitionerData.business_phone_nbr,
    email: data.practitionerData.business_email_addr,
    website: data.practitionerData.business_website,
    fax: data.practitionerData.business_fax_nbr,
    vatNumber: data.practitionerData.vat_nbr,
    practitionerStripeCustomerId: data.practitionerStripeAccount.customer_id,
    practitionerStripeAccountId: data.practitionerStripeAccount.account_id,
    serviceId: data.serviceId,
    serviceName: data.service.nhs_in
      ? `${data.serviceName} (NHS Funded)`
      : data.serviceName,
    serviceSlotTime: data.service.srvc_duration_time,
    serviceCost: data.service.nhs_in
      ? `${data.service.srvc_nhs_claims_amt}`
      : `${data.service.srvc_price_amt}`,
    isChargeable: data.service.chargeable_in,
    currencySymbol: "£",
    serviceDescription: data.service.nhs_in
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    appointmentType: data.serviceMethod,
    orderStatus: "Booked",
    serviceRequestNotes: data.serviceRequestNotes,
    interactiveFormData: data.interactiveFormData,
    fundingType: data.service.nhs_in ? "nhs" : "chargeable",
    deliveryMethod: [data.serviceMethod],
    start: data.startTime,
    end: data.endTime,
    duration: data.service.srvc_duration_time,
    allDay: false,
    recurrence: null,
    isSlotConfirmed: false,
    firstName: data.firstName,
    lastName: data.lastName,
    emailId: data.emailAddress,
    phoneNumber: data.phoneNumber,
    customer_addr1: data.address1,
    customer_addr2: data.address2,
    customer_zip: data.postCode,
    customer_city: data.city,
    customer_country: data.country,
    customer_birth_dt: data.birthday,
    customer_gender: data.gender,
    customer: data.customer,
  };
}

export function sampleData() {
  return [
    {
      organisationId: 34030,
      organisationCode: "FRL51",
      p_nbr: 56,
      organisationName: "MANGO PHARMACY",
      phone: "02089521390",
      email: "thekishanpatel@thekishanpatel.org",
      website: "https://www.nhs.uk/services/pharmacy/mango-pharmacy/FRL51",
      fax: "\n",
      vatNumber: "105571829",
      practitionerStripeCustomerId: "cus_QVlp05MqiHDD1W",
      practitionerStripeAccountId: "acct_1PekFrGh3VD4K0ec",
      serviceId: "Serv00006",
      serviceName: "NHS -ACUTE OTITIS MEDIA (NHS Funded)",
      serviceSlotTime: 15,
      serviceCost: "15",
      isChargeable: false,
      currencySymbol: "£",
      serviceDescription: "NHS -ACUTE OTITIS MEDIA (NHS Funded)",
      appointmentType: "In-Person",
      orderStatus: "Booked",
      serviceRequestNotes: "test",
      interactiveFormData: [
        {
          question: "Is the patient between the age of 1 and 17 years?",
          value: false,
        },
        {
          question: "Is the patient systemically very unwell?",
          value: false,
        },
        {
          question: "Has there been signs of a more serious illness?",
          value: false,
        },
        {
          question:
            "Is there a pre-existing comorbidity (this includes children with significant heart, lung, renal, liver or neuromuscular disease, immunosuppression, cystic fibrosis and young children who were born prematurely)?",
          value: false,
        },
      ],
      fundingType: "nhs",
      deliveryMethod: ["In-Person"],
      start: "2024-12-25T14:00:08Z",
      end: "2024-12-25T14:15:08Z",
      duration: 15,
      allDay: false,
      recurrence: null,
      isSlotConfirmed: false,
      firstName: "Kishan",
      lastName: "Patel",
      emailId: "test16@test.com",
      phoneNumber: "06095589535",
      customer_addr1: "44 Test Rd.",
      customer_addr2: "",
      customer_zip: "CM2 9XW",
      customer_city: "Chelmsford",
      customer_country: "United Kingdom",
      customer_birth_dt: "1996-10-31",
      customer_gender: "Male",
      customer: {
        role_id: 1,
        user_id: 153,
        customer_id: 76,
        customer_nhs_num: null,
        customer_name: "Kishan Patel",
        customer_addr1: "44 Test Rd.",
        customer_addr2: null,
        customer_city: "Chelmsford",
        customer_state: null,
        customer_zip: "CM2 9XW",
        customer_country: "United Kingdom",
        customer_email: "test16@test.com",
        customer_phone: "06095589535",
        customer_birth_dt: "1996-10-31",
        customer_gender: null,
        customer_race: null,
        customer_nhs_data: null,
        create_by_nm: "SYSTEM",
        update_by_nm: "SYSTEM",
        createdAt: "2024-12-11T03:32:36.000Z",
        updatedAt: "2024-12-11T03:58:35.000Z",
        user: {
          first_nm: "Kishan",
          last_nm: "Patel",
          user_nm: "test16@test.com",
        },
      },
    },
  ];
}
