import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";
import CarbsCalculatorForm from "../../components/forms/calculators/carbs-calculator";
import { Helmet } from "react-helmet-async";

export default function Carbs() {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let res = calculate(data);
      // Set Results
      setResults({ value: res });
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate
  function calculate(data) {
    if (data.isMetric) {
      // METRIC Calculation
      let value;
      let w = data && data.weight1 ? parseInt(data.weight1) : 0;
      let goal = data && data.goal ? data.goal : "Build Muscle";
      // Calculate
      if (goal === "light") {
        value = w * 4;
      }
      if (goal === "moderate") {
        value = w * 6;
      }
      if (goal === "high") {
        value = w * 8;
      }
      if (goal === "extreme") {
        value = w * 10;
      }
      return Math.round(value);
    } else {
      // Imperial Calculation
      let value;
      let w = data && data.weight2 ? parseInt(data.weight2) : 0;
      let goal = data && data.goal ? data.goal : "Build Muscle";
      // Calculate
      if (goal === "light") {
        value = w * (400 / 220.462);
      }
      if (goal === "moderate") {
        value = w * (600 / 220.462);
      }
      if (goal === "high") {
        value = w * (800 / 220.462);
      }
      if (goal === "extreme") {
        value = w * (1000 / 220.462);
      }
      return Math.round(value);
    }
  }
  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- Carbs Calculator</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/healthyliving/carb_foods.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- Carbs Calculator</h1>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <CarbsCalculatorForm handleData={handleData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h4>You Need</h4>
                  <h3>
                    <span className="fw-bolder text-danger">
                      {results.value}
                    </span>
                  </h3>
                  <h4>grams of Carbs / day</h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Carbs</h3>
                  <h5 className="card-title">What are Carbohydrates?</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Carbohydrates (carbs) are one of three primary
                      macronutrients that provide energy, along with fats and
                      proteins. Carbohydrates are broken down in the body or
                      converted into glucose, and serve as the body's main
                      source of energy. They can also be stored as energy in the
                      form of glycogen, or converted to fat (which can also be
                      used as a source of energy).
                    </p>
                  </ul>

                  <h5 className="card-title">Types of Carbohydrates:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      Carbohydrates are often classified as either simple
                      (monosaccharides and disaccharides) or complex
                      (polysaccharides or oligosaccharides), originally to
                      create a distinction between sugars and other
                      carbohydrates. However, there are many foods that contain
                      multiple types of carbohydrates, such as fruits and
                      vegetables, which can make the classification of certain
                      foods ambiguous. Although carbohydrates are not essential
                      nutrients (nutrients required for normal physiological
                      function that the body cannot synthesize), they are an
                      efficient source of energy that can potentially reduce the
                      risk of cardiovascular diseases, obesity, and type 2
                      diabetes if consumed in controlled amounts.
                    </p>
                    <p>
                      The BMR is measured under very restrictive circumstances
                      while awake. An accurate BMR measurement requires that a
                      person's sympathetic nervous system is inactive, which
                      means the person must be completely rested. Basal
                      metabolism is usually the largest component of a person's
                      total caloric needs. The daily caloric need is the BMR
                      value multiplied by a factor with a value between 1.2 and
                      1.9, depending on activity level.
                    </p>
                    <p>
                      In most situations, the BMR is estimated with equations
                      summarized from statistical data. The Harris-Benedict
                      Equation was one of the earliest equations introduced. It
                      was revised in 1984 to be more accurate and was used up
                      until 1990, when the Mifflin-St Jeor Equation was
                      introduced. The Mifflin-St Jeor Equation has been shown to
                      be more accurate than the revised Harris-Benedict
                      Equation. The Katch-McArdle Formula is slightly different
                      in that it calculates resting daily energy expenditure
                      (RDEE), which takes lean body mass into account, something
                      that neither the Mifflin-St Jeor nor the Harris-Benedict
                      Equation does. Of these equations, the Mifflin-St Jeor
                      Equation is considered the most accurate equation for
                      calculating BMR with the exception that the Katch-McArdle
                      Formula can be more accurate for people who are leaner and
                      know their body fat percentage. You can pick the equation
                      to be used in the calculation by expanding the settings.
                    </p>
                    <p>
                      The three main types of carbohydrates are sugar, starch,
                      and fiber:
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Sugars are the simplest form of carbohydrates and can be
                        found naturally in fruits, dairy, and vegetables; they
                        can also be found in processed form in candy, cookies,
                        cakes, and many beverages.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Starches are complex carbohydrates that can be found
                        naturally in many types of beans, vegetables, and
                        grains.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Fibers are complex carbohydrates that can be found in
                        fruits, whole grains, vegetables, and many types of
                        beans. Fibers are essential for digestion.
                      </li>
                    </p>
                    <p>
                      Generally, complex carbohydrates have greater nutritional
                      benefits than simple carbohydrates, which are sometimes
                      referred to as "empty carbs." Added sugars, a common form
                      of simple carbohydrates, have little nutritional value and
                      are not necessary for survival. While the body does
                      require some carbohydrates (which are broken down into
                      sugar), it is not necessary to consume sugary foods to
                      meet this need. Complex carbohydrates such as fiber-rich
                      fruits and vegetables, whole grains, legumes, and others,
                      also provide carbohydrates the body can use for energy to
                      function, along with many other nutrients it can use.
                      Complex carbs are also digested more slowly, allowing a
                      person to feel full for longer periods of time, which can
                      help when trying to control weight. On the other hand,
                      foods comprised of mainly simple carbohydrates such as
                      soda, cookies, juice, and other baked goods, often have
                      large amounts of sugars and fats, which may potentially
                      lead to weight gain and diabetes since they tend to be
                      easier to consume in excess.
                    </p>
                  </ul>

                  <h5 className="card-title">How Many Carbs Should I Eat?</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      While this estimate varies depending on a number of
                      factors, the Institute of Medicine recommends that a
                      minimum of 130 grams of carbohydrates be consumed daily
                      for adults. Other sources recommend that carbohydrates
                      should comprise 40-75% of daily caloric intake. Although
                      carbohydrates are not essential nutrients, and there are
                      many fad diets that highly restrict or even eliminate carb
                      intake, there are benefits to consuming a controlled
                      amount of "good" carbs (which will be described below).
                      When carbs are consumed in excess of what can be stored as
                      glycogen, they are converted to fats, which act as stored
                      energy. In a case where insufficient carbs and fats are
                      available to be used for energy, the body will start
                      breaking down protein instead, which can be problematic.
                      Proteins perform many essential functions in the body,
                      including serving as the building blocks for tissues and
                      organs, driving many chemical reactions throughout the
                      body, facilitating communication throughout the body,
                      transporting molecules, and many more.
                    </p>
                    <p>
                      It is worth noting that not all carbohydrates are made
                      equal. Certain sources of carbohydrates are better than
                      others. For example, whole grains, vegetables, fruits, and
                      beans are better sources of carbohydrates than white
                      bread, white rice, and those in processed foods. Within
                      the context of carbohydrates in a diet, the main
                      difference between simple and complex carbohydrates,
                      sometimes referred to as "refined" and "whole," or even
                      "bad" and "good" carbohydrates respectively, is that
                      refined carbohydrates have been stripped of natural fiber.
                      This is common in juices, pastries, bread, pasta, and many
                      other common foods. Fiber is necessary for digestion, and
                      it promotes healthy bowel movements and can, in some
                      cases, decrease the risk of certain chronic diseases,
                      according to the US Department of Agriculture. Whether or
                      not carbohydrates are good or bad is often the subject of
                      diet debates. This is because there is truth to both sides
                      of the argument in that not all carbohydrates are the same
                      and some are better than others, and carbohydrates can
                      affect different people in different ways. Below are some
                      of the key characteristics of good and bad carbs:
                    </p>
                    <p className="text-muted">
                      Good Carbs:
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Contain a low or moderate number of calories
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are high in nutrients
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Do not contain refined sugars or grains
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are high in natural fibers are low in sodium and
                        saturated fats are
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Low in, or do not contain, cholesterol and trans fats
                      </li>
                    </p>
                    <p className="text-muted">
                      Bad carbs essentially are the opposite of good carbs and:
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are high in calories
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are low in many nutrients
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are full of refined sugars (ex. corn syrup, white sugar,
                        honey, fruit juices)
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are low in fiber
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are high in sodium and may contain high levels of
                        saturated fat
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        May be high in cholesterol and trans fats
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        Are high in refined grains (ex. white flour)
                      </li>
                    </p>
                    <p>
                      How many carbohydrates a person consumes really depends on
                      many personal factors. There are situations in which a
                      low-carb diet can be beneficial, even life-changing, for
                      one person, but having a lower-carb diet will not
                      necessarily have health benefits for someone in a
                      different situation. Many healthy foods that are filled
                      with nutrients, such as vegetables, legumes, whole fruits,
                      nuts, seeds, and whole grains contain carbohydrates.
                      Carbohydrates are not inherently bad so long as sugary
                      drinks, fruit juices, and processed foods like cookies and
                      candy, are avoided, or consumed in moderation. Eat enough
                      carbs to suit your lifestyle and maybe seek out a
                      dietitian if considering any drastic changes to your diet.
                    </p>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
