import React from "react";
import { HashLink } from "react-router-hash-link";
import {
  getPractitionerByOds,
  getPractitionerStripeAccount,
} from "../../utils/pctmgmt.services";
import {
  capitalizeFirstLetter,
  formatAppointmentUrl,
} from "../../utils/helper";
import { Rating } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./services-search.css";

function Pharmacies({ pharmacies, handleSelection, metadata, serviceType }) {
  // Handle Click
  const handleClick = (pharm) => (event) => {
    getPractitionerByOds(pharm.organizationId, pharm.odsCode).then(
      async (response) => {
        let practitioner = response.data;
        let selectionData = {
          serviceName: metadata.serviceName,
          serviceId: metadata.serviceId,
          isNhs: metadata.isNhs,
          practitionerDataMongo: pharm,
          practitionerData: practitioner,
        };

        let practitionerStripeAccountResponse =
          await getPractitionerStripeAccount(practitioner.p_nbr);
        selectionData.practitionerStripeAccount =
          practitionerStripeAccountResponse &&
          practitionerStripeAccountResponse.data
            ? practitionerStripeAccountResponse.data
            : null;

        handleSelection(selectionData);
      },
    );
  };

  return (
    <div className="container bg-white-50">
      <div
        className="row align-items-center"
        style={{
          display: "flex",
          // flex: "fit-content",
          flexWrap: "nowrap",
          overflowX: "scroll",
          justifyContent: "safe center",
        }}
      >
        {pharmacies &&
          pharmacies.map((item, index) => (
            <Card
              key={index}
              className="col-8 col-lg-4 col-md-8 ml-lg-0 px-0 py-0 mx-4 my-2 bg-white rounded-3"
              sx={{
                boxShadow: "20",
                overflowX: "scroll",
                display: "inline-block",
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={item.image || "assets/images/logo-icon.png"}
                alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
              />
              <CardContent className="my-0">
                <Typography
                  className="my-0"
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.practitioner}
                </Typography>
                <Typography
                  className="my-0"
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  <Rating name="read-only" value={item.ratings} readOnly />
                </Typography>
                {item.distanceText && (
                  <Typography
                    className="my-0"
                    gutterBottom
                    variant="caption"
                    component="div"
                  >
                    <p>{item.distanceText}</p>
                  </Typography>
                )}
                <Typography
                  className="my-0"
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                >
                  <ul className="list-unstyled mt-lg-0 pt-lg-0 mb-0 pb-0">
                    <li className="d-flex ms-0 mb-0 ms-0 text-start">
                      <i className="ri-user-location-fill text-primary fw-normal mb-0">
                        <small
                          className="ms-2"
                          style={{
                            color: "#000000",
                            fontFamily: "sans-serif",
                            fontSize: "small",
                          }}
                        >
                          {`${capitalizeFirstLetter(item.addressLine1)}, ${
                            item.addressLine2
                              ? capitalizeFirstLetter(item.addressLine2) + ", "
                              : ""
                          }${capitalizeFirstLetter(
                            item.cityNm,
                          )}, ${item.countryNm.toUpperCase()}`}
                        </small>
                      </i>
                    </li>
                    <li className="d-flex ms-0 mb-0 ms-0 text-start">
                      <i className="ri-phone-fill text-primary fw-normal mb-0">
                        <small
                          className="ms-2"
                          style={{
                            color: "#000000",
                            fontFamily: "sans-serif",
                            fontSize: "small",
                          }}
                        >
                          {item.phoneNum}
                        </small>
                      </i>
                    </li>
                    <li className="d-flex ms-0 mb-0 ms-0 text-start">
                      <i className="ri-mail-add-fill text-primary fw-normal mb-0">
                        <small
                          className="ms-2"
                          style={{
                            color: "#000000",
                            fontFamily: "sans-serif",
                            fontSize: "small",
                          }}
                        >
                          {item.emailAddress
                            ? item.emailAddress.toLowerCase()
                            : "contact@ziplanerx.com"}
                        </small>
                      </i>
                    </li>
                    <li className="d-flex ms-0 mb-0 ms-0 text-start">
                      <i className="ri-medicine-bottle-fill text-primary fw-normal mb-0 text-start">
                        <small
                          className="ms-2"
                          style={{
                            color: "#000000",
                            fontFamily: "sans-serif",
                            fontSize: "small",
                          }}
                        >
                          {item.services && item.services.length > 0
                            ? item.services[0].serviceNm.toUpperCase()
                            : ""}
                        </small>
                      </i>
                    </li>
                    <li className="d-flex ms-0 mb-0 ms-0 text-start">
                      <i className="ri-money-pound-box-fill text-primary fw-normal mb-0">
                        <small
                          className="ms-2"
                          style={{
                            color: "#000000",
                            fontFamily: "sans-serif",
                            fontSize: "small",
                          }}
                        >
                          {item.services && item.services.length > 0
                            ? `£ ${item.services[0].cost}`
                            : ""}
                        </small>
                      </i>
                    </li>
                  </ul>
                </Typography>
              </CardContent>
              <CardActions className="my-0 py-0 pb-1 align-content-center justify-content-center">
                <HashLink
                  className="my-0 bg-primary text-color-white w-25 rounded-2"
                  size="medium"
                  to={formatAppointmentUrl(serviceType)}
                  onClick={handleClick(item)}
                >
                  Book
                </HashLink>
              </CardActions>
            </Card>
          ))}
      </div>
    </div>
  );
}

export default Pharmacies;
