import React, { useEffect, useState } from "react";

function BilledUser({
  firstName,
  firstNameChange,
  firstNameValidation,
  lastName,
  lastNameChange,
  lastNameValidation,
  emailAddress,
  emailAddressChange,
  emailValidation,
  data,
}) {
  const emailList =
    data && data.length > 0 ? data.map((item) => item.emailId) : [];

  const customerNames =
    data && data.length > 0
      ? data.map((item) => `${item.firstName} ${item.lastName}`)
      : [];
  const [customerName, setCustomerName] = useState(null);

  // Use Effect
  useEffect(() => {
    if (data && data.length > 0) {
      emailAddressChange({ target: { value: data[0].emailId } });
      firstNameChange({ target: { value: data[0].firstName } });
      lastNameChange({ target: { value: data[0].lastName } });
      setCustomerName(`${data[0].firstName} ${data[0].lastName}`);
    }
  }, [data]);

  // Handler
  const handleNameChange = (e) => {
    setCustomerName(e.target.value);
    firstNameChange({ target: { value: data[e.target.value].firstName } });
    lastNameChange({ target: { value: data[e.target.value].lastName } });
  };

  return (
    <div className="row">
      <div
        className="text-start px-2"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <h5>Billing</h5>
      </div>
      <div
        className="col-12 border-0 rounded p-0"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="col-12 rounded" style={{ backgroundColor: "#fff" }}>
          <div className="row">
            {/*Email Address*/}
            <div className="col-lg-4">
              <div className="form-group d-flex px-2 text-start">
                <label className="pt-2 me-2">Select Email</label>
                <select
                  name="email"
                  id="email"
                  className="form-control mt-1 w-50 h-25 py-0"
                  required={true}
                  placeholder="Your email :*"
                  value={emailAddress}
                  onChange={emailAddressChange}
                  onSelect={emailAddressChange}
                >
                  <option value="" disabled>
                    -- Select Email --
                  </option>
                  {emailList.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/*Customer Name*/}
            <div className="col-lg-4">
              <div className="form-group d-flex px-2 text-start">
                <label className="pt-2 me-2">Select Name</label>
                <select
                  name="name"
                  id="name"
                  className="form-control mt-1 w-50 h-25 py-0"
                  required={true}
                  placeholder="Your Name :*"
                  value={customerName}
                  onChange={handleNameChange}
                  onSelect={handleNameChange}
                >
                  <option value="" disabled>
                    -- Select Name --
                  </option>
                  {customerNames.map((name, index) => (
                    <option key={index} value={index}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/*/!*First Name*!/*/}
            {/*<div className="col-lg-4">*/}
            {/*  <div className="form-group d-flex px-2 text-start">*/}
            {/*    <label className="pt-2 me-2" style={{ paddingRight: 10 }}>*/}
            {/*      First Name*/}
            {/*    </label>*/}
            {/*    <input*/}
            {/*      name="firstName"*/}
            {/*      id="firstName"*/}
            {/*      type="text"*/}
            {/*      className="form-control mt-1 w-50 h-25"*/}
            {/*      required={true}*/}
            {/*      placeholder="Patient First Name :*"*/}
            {/*      value={firstName}*/}
            {/*      onChange={firstNameChange}*/}
            {/*    />*/}
            {/*    {!firstNameValidation.valid ? (*/}
            {/*      <span className="text-danger" style={{ fontSize: "smaller" }}>*/}
            {/*        {" "}*/}
            {/*        {firstNameValidation.message}{" "}*/}
            {/*      </span>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*/!*Last Name*!/*/}
            {/*<div className="col-lg-4">*/}
            {/*  <div className="form-group d-flex px-2 text-start">*/}
            {/*    <label className="pt-2 me-2" style={{ paddingRight: 10 }}>*/}
            {/*      Last Name*/}
            {/*    </label>*/}
            {/*    <input*/}
            {/*      name="lastName"*/}
            {/*      id="lastName"*/}
            {/*      type="text"*/}
            {/*      className="form-control mt-1 w-50 h-25"*/}
            {/*      required={true}*/}
            {/*      placeholder="Patient Last Name :*"*/}
            {/*      value={lastName}*/}
            {/*      onChange={lastNameChange}*/}
            {/*    />*/}
            {/*    {!lastNameValidation.valid ? (*/}
            {/*      <span className="text-danger" style={{ fontSize: "smaller" }}>*/}
            {/*        {" "}*/}
            {/*        {lastNameValidation.message}{" "}*/}
            {/*      </span>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BilledUser;
