import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  RiCalendar2Fill,
  RiCalendarFill,
  RiCheckboxFill,
  RiCloseFill,
  RiHashtag,
  RiHome2Fill,
  RiMailFill,
  RiMoneyPoundBoxFill,
  RiPhoneFill,
  RiUser2Fill,
} from "react-icons/ri";
import { LuClipboardList, LuClock2 } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import DatePickerComponent from "../fields/DatePickerComponent";
import TimePickerComponent from "../fields/TimePickerComponent";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { getPractitionerById } from "../../utils/pctmgmt.services";
import usePractitionerData from "../../hooks/usePractitionerData";
import {
  patientCancel,
  rescheduleAppointment,
} from "../../utils/sales.service";

export default function AppointmentsTabCrud({ item, isMoreThan24hoursAway }) {
  // Variables
  const [showReschedule, setShowReschedule] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).add(1, "days").toDate(),
  );
  const [startTime, setStartTime] = useState(null);
  const [startTimeFormatted, setStartTimeFormatted] = useState(null);
  const [practitioner, setPractitioner] = useState(null);
  const [businessDays, setBusinessDays] = useState(null);
  const [businessStartTime, setBusinessStartTime] = useState(null);
  const [businessEndTime, setBusinessEndTime] = useState(null);
  const [appointmentDateValidation, setAppointmentDateValidation] = useState({
    valid: false,
    message: "Please Select Date",
  });
  const [appointmentTimeValidation, setAppointmentTimeValidation] = useState({
    valid: false,
    message: "Please Select Time",
  });

  useEffect(() => {
    if (showReschedule || showCancel) {
      getPractitionerById(item?.p_nbr)
        .then((res) => {
          if (res.data) {
            setPractitioner(res.data);
            setBusinessDays(res.data.business_days_json);
            setBusinessStartTime(res.data.business_hours_start_tm_json);
            setBusinessEndTime(res.data.business_hours_close_tm_json);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [showReschedule, showCancel]);

  // Use custom hooks for practitioner data
  const { overbookedSlots, practitionerBlockedSlots } = usePractitionerData(
    practitioner,
    startDate,
  );

  // Helper and Handler Functions
  const handleToggleReschedule = () => {
    setShowReschedule(!showReschedule);
  };
  const handleToggleCancel = () => {
    setShowCancel(!showCancel);
  };

  const handleDateChange = (date) => {
    if (date) {
      // Get Business Hour Start:
      let startHour =
        businessStartTime && businessEndTime
          ? setHours(
              setMinutes(new Date(), 0),
              parseInt(
                getBusinessHoursForDay(
                  moment(startDate).format("dddd"),
                ).start.split(":")[0],
              ),
            )
          : setHours(startDate, 9);
      startHour = startHour.getHours() || 0;
      date = moment(date).hour(startHour).minute(0).toDate();
      setStartDate(date);
      handleTimeChange(date);
      setAppointmentDateValidation({ valid: true, message: "" });
    } else {
      setAppointmentDateValidation({
        valid: false,
        message: "Please Select Date",
      });
    }
  };

  const handleTimeChange = (time) => {
    if (time) {
      let fmttime = moment(time).utc().format();
      setStartTime(time);
      setStartTimeFormatted(fmttime);
      setAppointmentTimeValidation({ valid: true, message: "" });
    } else {
      setAppointmentTimeValidation({
        valid: false,
        message: "Please Select Time",
      });
    }
  };

  const isBusinessDay = (date) => {
    const dayName = moment(date).format("dddd");
    return businessDays ? businessDays[dayName] : true;
  };

  const getBusinessHoursForDay = (dayName) => {
    return {
      start: businessStartTime ? businessStartTime[dayName] : null,
      close: businessEndTime ? businessEndTime[dayName] : null,
    };
  };

  const getMinTime = () => {
    const dayName = moment(startDate).format("dddd");
    const startHour = getBusinessHoursForDay(dayName).start || "09:00";
    return moment(startHour, "HH:mm").toDate();
  };

  const getMaxTime = () => {
    const dayName = moment(startDate).format("dddd");
    const endHour = getBusinessHoursForDay(dayName).close || "17:00";
    return moment(endHour, "HH:mm").toDate();
  };

  const rescheduleAppointmentSubmit = () => {
    // Create Payload
    let payload = {
      p_nbr: item.p_nbr,
      appointment_id: item.appointment_id,
      booking_id: item.booking_id,
      appointment_start_dttm: moment(startTime).utc().format(),
      srvc_id: item.srvc_id,
    };

    // Reschedule
    rescheduleAppointment(payload).then((r) => {
      if (r) {
        window.location.reload();
      }
    });
  };

  const cancelAppointmentSubmit = () => {
    // Create Payload
    let payload = {
      p_nbr: item.p_nbr,
      appointment_id: item.appointment_id,
      booking_id: item.booking_id,
      appointment_start_dttm: moment(startTime).utc().format(),
      srvc_id: item.srvc_id,
    };

    // Reschedule
    patientCancel(payload).then((r) => {
      if (r) {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <td className="text-left p-3">
        <div className="flex space-x-2">
          {/* Invoice */}

          <Link
            to={`/book/services/invoice?bookingId=${item.booking_id}`}
            target="_blank"
            className="btn btn-icon btn-primary mx-1"
          >
            <LuClipboardList />
          </Link>

          {/* Reschedule */}
          {isMoreThan24hoursAway && (
            <button
              className="btn btn-icon btn-primary mx-1"
              onClick={handleToggleReschedule}
              aria-label="Reschedule"
            >
              <LuClock2 />
            </button>
          )}
          {/* Cancel */}
          {isMoreThan24hoursAway && (
            <button
              className="btn btn-icon btn-danger mx-1"
              onClick={handleToggleCancel}
              aria-label="Cancel"
            >
              <RiCloseFill />
            </button>
          )}
        </div>
      </td>

      <Modal
        show={showReschedule}
        onHide={handleToggleReschedule}
        size="lg"
        animation={true}
        centered
        className="bg-transparent"
        style={{ width: "100%" }}
        dialogClassName="custom-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h2">
            {`Reschedule Appointment | ${item.srvc_nm}`}
          </Modal.Title>
        </Modal.Header>
        {/*Modal Body*/}
        <Modal.Body>
          <div className="p-2">
            <ul className="list-inline mb-0 flex row justify-between fw-normal">
              {/*Right Side Column*/}
              <li className="col-lg-6">
                <ul className="list-inline mt-0 mb-0">
                  {/*Practitioner*/}
                  <li className="my-2 flex items-center">
                    <RiHome2Fill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata.organization.organizationName}`}
                  </li>
                  {/*Date and Time*/}
                  <li className="my-2 flex items-center">
                    <RiCalendar2Fill className="text-primary text-lg me-2" />
                    {moment(item.appointment_start_dttm).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a",
                    )}
                  </li>
                  {/*Requested*/}
                  <li className="my-2 flex items-center">
                    <RiCalendarFill className="text-primary text-lg me-2" />
                    Booked on{" "}
                    {moment(item.appointment_booked_dttm).format(
                      "MMMM Do YYYY",
                    )}
                  </li>
                  {/*Status*/}
                  <li className="my-2 flex items-center">
                    <RiCheckboxFill className="text-primary text-lg me-2" />
                    {`${item.appointment_status_desc}${
                      item.appointment_status_desc === "Cancelled"
                        ? ` | ${item.appointment_sub_status_desc}`
                        : ""
                    }`}
                  </li>
                  {/*BookingId*/}
                  <li className="my-2 flex items-center">
                    <RiHashtag className="text-primary text-lg me-2" />
                    {`${item?.booking_id}`}
                  </li>
                  {/*Patient*/}
                  <li className="my-2 flex items-center">
                    <RiUser2Fill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.firstName} ${item?.appointment_metadata?.lastName}`}
                  </li>
                  {/*Payee*/}
                  <li className="my-2 flex items-center">
                    <RiMoneyPoundBoxFill className="text-primary text-lg me-2" />
                    {`Paid By ${
                      item?.appointment_metadata?.payee_customer_nm
                        ? item?.appointment_metadata?.payee_customer_nm
                        : `${item?.appointment_metadata?.firstName} ${item?.appointment_metadata?.lastName}`
                    }`}
                  </li>
                  {/*Email*/}
                  <li className="my-2 flex items-center">
                    <RiMailFill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.emailAddress}`}
                  </li>
                  {/*Phone*/}
                  <li className="my-2 flex items-center">
                    <RiPhoneFill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.phoneNumber}`}
                  </li>
                </ul>
              </li>
              <li className="col-lg-6 mx-0 px-0">
                <p className="text-primary fw-bold text-center">Reschedule</p>
                {/* Date Picker */}
                <div className="col-lg-6">
                  <div className="d-flex align-items-start">
                    <label className="col-2 col-lg-4 fw-bold text-start">
                      Date*
                    </label>
                    <div className="">
                      <DatePickerComponent
                        selectedDate={startDate}
                        onChange={handleDateChange}
                        filterDate={isBusinessDay}
                        minDate={moment(new Date()).add(1, "days").toDate()}
                        placeholderText="Choose Appointment Date*"
                        ariaLabel="Appointment Date"
                        required={true}
                        // validation={appointmentDateValidation}
                      />
                    </div>
                  </div>
                </div>

                {/* Time Picker */}
                <div className="col-lg-6">
                  <div className="d-flex align-items-start">
                    <label className="col-2 col-lg-4 fw-bold text-start">
                      Time*
                    </label>
                    <div className="">
                      <TimePickerComponent
                        selectedTime={startTime}
                        onChange={handleTimeChange}
                        excludeTimes={[
                          ...overbookedSlots,
                          ...practitionerBlockedSlots,
                        ]}
                        minTime={getMinTime()}
                        maxTime={getMaxTime()}
                        ariaLabel="Appointment Time"
                        required={true}
                        // validation={appointmentTimeValidation}
                      />
                    </div>
                  </div>
                </div>

                {/*Submit*/}
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary fw-bold"
                    onClick={rescheduleAppointmentSubmit}
                    aria-label="Reschedule"
                    disabled={
                      !appointmentDateValidation.valid ||
                      !appointmentTimeValidation.valid
                    }
                  >
                    Reschedule
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* Cancel Modal */}
      <Modal
        show={showCancel}
        onHide={handleToggleCancel}
        size="lg"
        animation={true}
        centered
        className="bg-transparent"
        style={{ width: "100%" }}
        dialogClassName="custom-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-lg">
            {`Cancel Appointment | ${item.srvc_nm || "Service Name"}`}
          </Modal.Title>
        </Modal.Header>
        {/*Modal Body*/}
        <Modal.Body>
          <div className="p-2">
            <ul className="list-inline mb-0 flex row justify-between fw-normal">
              {/*Right Side Column*/}
              <li className="col-lg-6">
                <ul className="list-inline mt-0 mb-0">
                  {/*Practitioner*/}
                  <li className="my-2 flex items-center">
                    <RiHome2Fill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata.organization.organizationName}`}
                  </li>
                  {/*Date and Time*/}
                  <li className="my-2 flex items-center">
                    <RiCalendar2Fill className="text-primary text-lg me-2" />
                    {moment(item.appointment_start_dttm).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a",
                    )}
                  </li>
                  {/*Requested*/}
                  <li className="my-2 flex items-center">
                    <RiCalendarFill className="text-primary text-lg me-2" />
                    Booked on{" "}
                    {moment(item.appointment_booked_dttm).format(
                      "MMMM Do YYYY",
                    )}
                  </li>
                  {/*Status*/}
                  <li className="my-2 flex items-center">
                    <RiCheckboxFill className="text-primary text-lg me-2" />
                    {`${item.appointment_status_desc}${
                      item.appointment_status_desc === "Cancelled"
                        ? ` | ${item.appointment_sub_status_desc}`
                        : ""
                    }`}
                  </li>
                  {/*BookingId*/}
                  <li className="my-2 flex items-center">
                    <RiHashtag className="text-primary text-lg me-2" />
                    {`${item?.booking_id}`}
                  </li>
                  {/*Patient*/}
                  <li className="my-2 flex items-center">
                    <RiUser2Fill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.firstName} ${item?.appointment_metadata?.lastName}`}
                  </li>
                  {/*Payee*/}
                  <li className="my-2 flex items-center">
                    <RiMoneyPoundBoxFill className="text-primary text-lg me-2" />
                    {`Paid By ${
                      item?.appointment_metadata?.payee_customer_nm
                        ? item?.appointment_metadata?.payee_customer_nm
                        : `${item?.appointment_metadata?.firstName} ${item?.appointment_metadata?.lastName}`
                    }`}
                  </li>
                  {/*Email*/}
                  <li className="my-2 flex items-center">
                    <RiMailFill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.emailAddress}`}
                  </li>
                  {/*Phone*/}
                  <li className="my-2 flex items-center">
                    <RiPhoneFill className="text-primary text-lg me-2" />
                    {`${item?.appointment_metadata?.phoneNumber}`}
                  </li>
                </ul>
              </li>
              <li className="col-lg-6 mx-0 px-0">
                <p
                  className="text-danger fw-bolder text-center my-0"
                  style={{ fontSize: "medium" }}
                >
                  Are you sure you want to cancel?
                </p>

                {/*Submit*/}
                <div className="col-12 text-center">
                  <button
                    className="col-12 col-lg-4 mx-lg-2 btn btn-muted fw-bold"
                    style={{ fontSize: "small" }}
                    onClick={handleToggleCancel}
                    aria-label="close"
                  >
                    No, keep my appointment
                  </button>
                  <button
                    className="col-12 col-lg-4 mx-lg-2 btn btn-danger fw-bold"
                    style={{ fontSize: "small" }}
                    onClick={cancelAppointmentSubmit}
                    aria-label="cancel"
                  >
                    Cancel Appointment
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
