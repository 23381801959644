import React, { useEffect, useRef } from "react";
import "./services-search.css";
import Radar from "radar-sdk-js";

function PostalCodeInput({ postalCode, setPostalCode, setLocation }) {
  const autocompleteRef3 = useRef(null);
  useEffect(() => {
    Radar.initialize(process.env.REACT_APP_RADAR_PK);
    try {
      autocompleteRef3.current = Radar.ui.autocomplete({
        container: "autocomplete_postal",
        width: "1200px",
        responsive: true,
        layers: ["postalCode"],
        minCharacters: 3,
        limit: 10,
        onSelection: (address) => {
          if (address.postalCode) {
            setPostalCode(address.postalCode);
            setLocation({
              latitude: address.latitude,
              longitude: address.longitude,
              postalCode: address.postalCode,
            });
          }
        },
      });
    } catch (err) {}

    return () => {
      autocompleteRef3.current?.remove();
    };
  }, []);

  return (
    <div className="col-md mt-4 mt-sm-0">
      <div className="input-group bg-white border rounded">
        <span className="input-group-text border-0">
          <i className="ri-map-pin-line text-primary h5 fw-normal mb-0"></i>
        </span>
        <input
          id="autocomplete_postal"
          type="text"
          className="form-control border-0"
          placeholder="Postal Code:"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          aria-label="Postal Code"
        />
      </div>
    </div>
  );
}

export default PostalCodeInput;
