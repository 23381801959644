import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import { FiArrowRight, FiCompass, FiMail, FiPhone } from "react-icons/fi";
import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { Helmet } from "react-helmet-async";

export default function Privacy() {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- Privacy</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/bg/privacy.avif)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- Privacy</h1>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Privacy Policy:</h3>
                  <p className="text-muted">
                    Thank you for choosing{" "}
                    <span className="text-primary">www.ZipLaneRx.com</span> for
                    your healthcare needs. Your privacy and security are of
                    utmost importance to us. This Privacy Policy outlines how we
                    collect, use, disclose, and protect your personal
                    information when you use our platform. By accessing or using{" "}
                    <span className="text-primary">www.ZipLaneRx.com</span> ,
                    you agree to the terms of this Privacy Policy.
                  </p>

                  <h5 className="card-title">1. Information We Collect</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      We collect personal information from you when you use our
                      platform, including but not limited to:
                    </p>
                    <p className="text-muted">
                      For Customers:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Contact information (e.g., name, email address, phone
                        number)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Medical information (e.g., medical history,
                        prescriptions, health conditions)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Payment information (e.g., credit card details)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Usage data (e.g., browsing history, interaction with the
                        platform)
                      </li>
                    </p>
                    <p className="text-muted">
                      For Practitioners:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Practitioner Contact information (e.g., name, email
                        address, phone number)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Business information (e.g., name, email address,
                        location, phone number, website, VAT, ODS code, GPhc
                        number)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Payment information (e.g., Bank details, credit card
                        details)
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Usage data (e.g., browsing history, interaction with the
                        platform)
                      </li>
                    </p>
                    <p>
                      If you have registered with ZipLaneRx as a Customer or
                      Practitioner but are not actively using the account you
                      will periodically receive a message to review and update
                      your personal information. Under the Data Protection Act
                      2018 we have a legal obligation to ensure that our
                      customer data is ‘accurate’ and is up to date.
                    </p>
                  </ul>

                  <h5 className="card-title">2. How We Use Your Information</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      We use your personal information to:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Provide and improve our services
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Process transactions
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Communicate with you
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Personalize your experience
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Provide business improvement insights and segments
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Ensure compliance with legal requirements
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">3. Sharing Your Information</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      We may share your personal information with:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Healthcare providers to facilitate your appointments and
                        treatments
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Payment processors and third-parties for transaction
                        processing
                      </li>
                      {/*<li>*/}
                      {/*  <FiArrowRight className="fea icon-sm me-2 mb-0" />*/}
                      {/*  Third-party service providers for analytics, marketing,*/}
                      {/*  and other purposes*/}
                      {/*</li>*/}
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Legal authorities when required by law or to protect our
                        rights
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">4. Data Security</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      We implement industry-standard security measures to
                      protect your personal information from unauthorized
                      access, disclosure, alteration, or destruction in
                      compliance with GDPR and Data Protection Act 2018.
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Security - OAuth for authentication.
                      </li>
                      {/*and authorization*/}
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Service to Service interactions - SSL’s
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Data Encryption - AES 256 bit encryption.
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">5. Your Choices</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      You have the right to:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Access, update, or delete your personal information
                      </li>
                      {/*<li>*/}
                      {/*  <FiArrowRight className="fea icon-sm me-2 mb-0" />*/}
                      {/*  Opt-out of certain communications*/}
                      {/*</li>*/}
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Manage your cookie preferences through consent
                        management while visiting the website.
                      </li>
                    </p>
                    <p>
                      As a customer, you can access, manage and update personal
                      information by logging in at{" "}
                      <span className="text-primary">
                        www.ziplanerx.com/patient-profile
                      </span>
                      {/*. As a practitioner, you can manage these by logging in at{" "}*/}
                      {/*<span className="text-primary">*/}
                      {/*  www.ziplanerx.com/practitioner/sign-in*/}
                      {/*</span>*/}. You can also contact us at{" "}
                      <span className="text-primary">
                        contact@ziplanerx.com
                      </span>{" "}
                      if you need assistance.
                    </p>
                  </ul>

                  <h5 className="card-title">6. Children’s Privacy</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Our platform is not intended for use by children under the
                      age of 18. We do not knowingly collect personal
                      information from children.
                    </p>
                  </ul>

                  <h5 className="card-title">7. Third-Party Links</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Our platform may contain links to third-party websites or
                      services. We are not responsible for the privacy practices
                      of these third parties.
                    </p>
                  </ul>

                  <h5 className="card-title">
                    8. Changes to This Privacy Policy
                  </h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      We reserve the right to update or modify this Privacy
                      Policy at any time. We will notify you of any changes by
                      posting the new Privacy Policy on this page.
                    </p>
                  </ul>

                  <h5 className="card-title">9. Contact Us</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      <span className="text-primary">www.ZipLaneRx.com</span> is
                      a digital asset provided by ZipLaneRx LLC, a registered
                      Delaware, USA company.
                      {/*Delaware, USA company with registration # 999999999. In*/}
                      {/*UK, it is operated by its subsidiary company with*/}
                      {/*registration number # 888888888.*/}
                    </p>
                    <p className="text-muted">
                      If you have any questions or concerns about this privacy
                      policy you may contact us at:
                      <li>
                        <FiMail className="fea icon-sm me-2 mb-0" />
                        Email:{" "}
                        <span className="text-primary">
                          contact@ziplanerx.com
                        </span>
                      </li>
                      <li>
                        <FiCompass className="fea icon-sm me-2 mb-0" />
                        Address:{" "}
                        <span className="text-primary">
                          ZipLaneRx, 8 The Green STE A, Dover, DE 19901, USA
                        </span>
                      </li>
                      <li>
                        <FiPhone className="fea icon-sm me-2 mb-0" />
                        Phone:{" "}
                        <span className="text-primary">+1 (302)-446-4504</span>
                      </li>
                    </p>
                    {/*<p>*/}
                    {/*  <span className="text-primary">www.ZipLaneRx.com</span> is*/}
                    {/*  a digital asset provided by ZipLaneRx LLC, a registered*/}
                    {/*  Delaware, USA company.*/}
                    {/*  /!*Delaware, USA company with registration # 999999999. In*!/*/}
                    {/*  /!*UK, it is operated by its subsidiary company with*!/*/}
                    {/*  /!*registration number # 888888888.*!/*/}
                    {/*</p>*/}
                  </ul>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      In the UK, it is operated by its subsidiary company
                      ZipLaneRx Limited.
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        Registration Number:{" "}
                        <span className="text-primary">15620480</span>
                      </li>
                      <li>
                        <FiCompass className="fea icon-sm me-2 mb-0" />
                        Address:{" "}
                        <span className="text-primary">
                          54 Gardiner Way, Chelmsford, Essex, CM1 6BS, UNITED
                          KINGDOM
                        </span>
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">10. Consent</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      By using our platform, you consent to the collection, use,
                      and disclosure of your personal information as described
                      in this Privacy Policy.
                    </p>
                  </ul>

                  <h5 className="card-title">ZipLaneRx</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">Last Updated: March 10, 2024</p>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
