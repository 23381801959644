import React, { Fragment, useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BookAppointmentForm from "../../components/forms/book-appointment-form/BookAppointmentForm";
import { serviceLogoCarousel, ziplanerxBookData } from "../../data/data";
import GPSearchFields from "../../components/ServicesSearch/GpServicesSearch";
import { formServiceSearchData } from "../../utils/helper";
import { Link } from "react-router-dom";
import ZlrxSlider from "../../components/Tinyslider";
import LogoCarousel from "../../components/LogoCarousel/LogoCarousel";
import { getPractitionerService } from "../../utils/pctmgmt.services";
import Pharmacies from "../../components/ServicesSearch/Pharmacies";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";
import { Helmet } from "react-helmet-async";
import ScrollTop from "../../components/scrollTop";

function GPServices({ serviceAddToCart }) {
  const getInitialSelection = () => "Select a Service";
  const [selectedService, setSelectedService] = useState(getInitialSelection);
  const [selectedServiceObject, setSelectedServiceObject] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [showPharmacies, setShowPharmacies] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);
  const [consentTemplate, setConsentTemplate] = useState(null);
  let [showInvoice, setShowInvoice] = useState(false);
  const [serviceSearchData, setServiceSearchData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [showGPAboutUs, setShowGPAboutUs] = useState(false);
  const [webstoreReferred, setWebstoreReferred] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const bookingFormRef = useRef(null);
  const homeRef = useRef(null);
  const [tabActiveIndex, setTabActiveIndex] = useState(1);
  const [serviceType, setServiceType] = useState("GP");
  const [location, setLocation] = useState(null);

  // Location
  Radar.initialize(process.env.REACT_APP_RADAR_PK);
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  useEffect(() => {
    if (!location) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Getting Location
          const { latitude, longitude } = position.coords;

          // Getting Postal Code
          Radar.reverseGeocode({
            latitude,
            longitude,
          })
            .then((result) => {
              // Setting Location
              setLocation({
                latitude,
                longitude,
                postalCode: result.addresses[0].postalCode,
              });
            })
            .catch((err) => {
              console.log("Error in reverse geocoding.");
              console.log(err);
              setLocation({
                latitude: 51.50735,
                longitude: -0.12776,
                postalCode: "WC2N 5DN",
              });
            });
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.log("Error fetching location:", error);
          console.log("Setting location to London");
          setLocation({
            latitude: 51.50735,
            longitude: -0.12776,
            postalCode: "WC2N 5DN",
          });
        },
      );
    }
  }, [location]);

  // Query Params
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query) {
      if (query.get("webstore_referred")) {
        setWebstoreReferred(true);
        setQueryParams(query);
      } else {
        setWebstoreReferred(false);
        setQueryParams(null);
      }
    }
  }, []);

  useEffect(() => {
    if (webstoreReferred) {
      // Get Service Info
      formServiceSearchData(
        queryParams.get("srvc_id")?.toLowerCase(),
        queryParams.get("p_nbr"),
      ).then((resp) => {
        setServiceSearchData(resp);

        // Scroll
        bookingFormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    } else {
      setServiceSearchData(null);
    }
  }, [webstoreReferred]);

  // Use Effect
  useEffect(() => {
    setShowInvoice(false);
  }, []);

  // Handlers
  const resetSelection = () => {
    setSelectedService(getInitialSelection);
    setSelectedServiceObject(null);
    setSelectedServiceId(null);
    setConsentTemplate(null);
    setShowPharmacies(false);
    setPharmacies([]);
    setServiceSearchData(null);
    // Scroll
    homeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleServiceSearch = (selectionData) => {
    setServiceSearchData(selectionData);
    setShowGPAboutUs(true);
  };

  const handleOrderData = (orderData) => {
    setOrderData(orderData);
  };

  const handlePaymentData = (paymentData) => {
    setPaymentData(paymentData);
    setShowInvoice(true);
    window.history.replaceState(null, null, "/book/services/invoice");
  };

  // BookFormProps
  let bookingProps = {
    serviceSearchData: serviceSearchData,
  };

  // Handle Pharmacy Selection
  const handlePharmacySelection = (data) => {
    let practitioner = data?.practitionerData;
    let p_nbr = practitioner?.p_nbr;
    getPractitionerService(p_nbr, selectedServiceId).then((response) => {
      data.service = response.data;
      data.consentTemplate = consentTemplate;
      handleServiceSearch(data);
    });
  };

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- Book Private GP Consultations and Services</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <div
        style={{
          backgroundImage: `url(assets/images/GP1.avif)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          backgroundAttachment: "scroll",
          overflow: "scroll",
        }}
      >
        <section
          className="bg-half-170 d-table w-100 pb-3"
          id="home"
          ref={homeRef}
        >
          <div className="bg-overlay bg-overlay-dark">
            <h1 style={{ opacity: "0" }}>
              ZipLaneRx -- Book Private GP Consultations and Services
            </h1>
          </div>
          <div className="container">
            <div className="row justify-content-center mt-lg-0 mt-sm-5 pt-lg-0 pt-sm-5">
              <div className="col-12">
                <div
                  className="heading-title text-center mt-sm-5 mt-lg-0 pt-lg-0 pt-sm-5"
                  // style={{ marginTop: 150 }}
                >
                  <div className="col-12 mt-0 mb-0 pb-0 pt-sm-5 pt-lg-0">
                    <ul className="nav nav-pills nav-justified flex-column flex-sm-row shadow overflow-hidden bg-white-50 mt-3 mb-0 pb-0 rounded-top">
                      <li className="nav-item">
                        <Link
                          className={`${
                            tabActiveIndex === 1 ? "active" : ""
                          } nav-link rounded fw-bolder`}
                          style={{ fontSize: "medium" }}
                          to={null}
                          onClick={() => setTabActiveIndex(1)}
                        >
                          Search Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${
                            tabActiveIndex === 0 ? "active" : ""
                          } nav-link rounded fw-bolder`}
                          style={{ fontSize: "medium" }}
                          to={null}
                          onClick={() => setTabActiveIndex(0)}
                        >
                          Explore Services
                        </Link>
                      </li>
                    </ul>
                    <GPSearchFields
                      selectedService={selectedService}
                      setSelectedService={setSelectedService}
                      selectedServiceObject={selectedServiceObject}
                      setSelectedServiceObject={setSelectedServiceObject}
                      selectedServiceId={selectedServiceId}
                      setSelectedServiceId={setSelectedServiceId}
                      showPharmacies={showPharmacies}
                      setShowPharmacies={setShowPharmacies}
                      pharmacies={pharmacies}
                      setPharmacies={setPharmacies}
                      consentTemplate={consentTemplate}
                      setConsentTemplate={setConsentTemplate}
                      serviceSearchData={handleServiceSearch}
                      tabActiveIndex={tabActiveIndex}
                      serviceType={serviceType}
                      location={location}
                      setLocation={setLocation}
                    />
                  </div>
                </div>
              </div>
              <ZlrxSlider data={ziplanerxBookData} />
            </div>
          </div>
        </section>
      </div>
      <section
        ref={bookingFormRef}
        className="section mt-0 pt-2 pb-0 mb-0"
        id="appointment"
      >
        <div className="container mt-0">
          <div className="row align-items-lg-end">
            {showPharmacies && (
              <Pharmacies
                pharmacies={pharmacies}
                handleSelection={handlePharmacySelection}
                metadata={{
                  serviceName: selectedService,
                  serviceId: selectedServiceId,
                  isNhs: selectedService.isNhs,
                }}
                serviceType={serviceType}
              />
            )}
            {/* <AboutBookAppointment />*/}
            <BookAppointmentForm
              data={bookingProps}
              handleOrderData={handleOrderData}
              handlePaymentData={handlePaymentData}
              serviceAddToCart={serviceAddToCart}
              resetSelection={resetSelection}
              serviceType={serviceType}
            />
          </div>
        </div>
      </section>

      <section className="section mt-0 pt-3 pb-3">
        <div className="container mt-0">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-0 pb-0 text-center">
                <span
                  className="badge rounded-pill bg-soft-primary mb-3"
                  style={{ fontSize: "large" }}
                >
                  Our Medical Services
                </span>
                {/*<h4 className="title mb-4">Our Medical Services</h4>*/}
                <p className="text-muted mx-auto para-desc mb-0 pb-0">
                  We pride on making your service appointment seamless, secure,
                  and reliable!
                </p>
              </div>
              <div className={"row mt-0"}>
                <LogoCarousel data={serviceLogoCarousel} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}

export default GPServices;
