import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { Elements } from "@stripe/react-stripe-js";
import {
  createPractitionerInvoice,
  paymentIntentCallProxy,
} from "../../utils/stripe.service";
import { loadStripe } from "@stripe/stripe-js";
import { orderDeliveryMethods } from "../../data/data";
import {
  emailValidator,
  phoneValidator,
  postalCodeValidator,
} from "../../utils/validators";
import { getCustomer } from "../../utils/usermgmt.service";
import PaymentForm from "../../components/cart/PaymentForm";
import ServiceCartTable from "../../components/cart/ServiceCartTable";
import TotalSummary from "../../components/cart/TotalSummary";
import BilledUser from "../../components/cart/Billing";
import {
  finalizeNhsBookings,
  initiateAppointments,
} from "../../utils/sales.service";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet-async";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function ShopCart({
  cart,
  removeFromCart,
  clearCart,
  serviceCart,
  serviceRemoveFromCart,
  serviceClearCart,
}) {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // serviceCart =
  //   serviceCart && serviceCart.length === 0 ? sampleData() : serviceCart;
  // Functional Variables
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loadStrip, setLoadStripe] = useState(false);
  const [stripeApiUp, setStripeApiUp] = useState(true);
  const [stripeIntentData, setStripIntentData] = useState(null);
  const [data, setData] = useState(null);
  const [serviceOrderData, setServiceOrderData] = useState(null);
  const [nhsServicesPaymentIntents, setNhsServicesPaymentIntents] =
    useState(null);
  const [privateServicesPaymentIntents, setPrivateServicesPaymentIntents] =
    useState(null);
  let [actionCount, setActionCount] = useState(0);
  // Customer Information
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("United Kingdom");
  const [postCode, setPostCode] = useState(null);
  const [ageConsent, setAgeConsent] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  // Validation Variables
  const [validForm, setValidForm] = useState(false);
  const [showCheckout, setShowCheckout] = useState(true);
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    message: "",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: false,
    message: "",
  });
  const [addressValidation, setAddressValidaton] = useState({
    valid: false,
    message: "",
  });
  const [cityValidation, setCityValidation] = useState({
    valid: false,
    message: "",
  });
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    valid: false,
    message: "",
  });

  let vat = {
    Standard: 0.2,
    Low: 0.05,
    Zero: 0.0,
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  // Bindings
  // Customer Id
  useEffect(() => {
    setCustomerId(sessionStorage.getItem("customerId"));
  });

  // Customer Data
  useEffect(() => {
    if (customerId) {
      getCustomer(customerId).then((response) => {
        if (response.data) {
          setCustomer(response.data);
        }
      });
    }
  }, [customerId]);

  // Pre Populate
  useEffect(() => {
    if (customer) {
      if (customer?.user?.first_nm) {
        setFirstName(customer?.user?.first_nm);
        setFirstNameValidation({ valid: true, message: "" });
      }

      if (customer?.user?.last_nm) {
        setLastName(customer?.user?.last_nm);
        setLastNameValidation({ valid: true, message: "" });
      }

      if (customer?.customer_email) {
        setEmailAddress(customer?.customer_email);
        setEmailValidation({
          valid: emailValidator(customer?.customer_email),
          message: "Please Enter Valid Email Address",
        });
      }

      if (customer?.customer_phone) {
        setPhoneNumber(customer?.customer_phone);
        setPhoneNumberValidation({
          valid: phoneValidator(customer.customer_phone),
          message: "Please Enter Valid Phone Number",
        });
      }

      if (customer?.customer_addr1) {
        setAddress1(customer?.customer_addr1);
        setAddressValidaton({
          valid: true,
          message: "",
        });
      }

      if (customer?.customer_addr2) {
        setAddress2(customer?.customer_addr2);
      }

      if (customer?.customer_city) {
        setCity(customer?.customer_city);
        setCityValidation({
          valid: true,
          message: "",
        });
      }

      if (customer?.customer_zip) {
        setPostCode(customer?.customer_zip);
        setPostalCodeValidation({
          valid: postalCodeValidator(customer.customer_zip),
          message: "Please Enter Valid Postal Code",
        });
      }
    }
  }, [customer]);

  // TODO: Form Validator, will need to add the other fields back in the future
  useEffect(() => {
    setValidForm(
      firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid,
    );
  }, [firstNameValidation, lastNameValidation, emailValidation]);

  useEffect(() => {
    if (cart) {
      setData(Object.values(cart));
    }
  }, [cart, actionCount]);

  useEffect(() => {
    setDeliveryFee(getDeliveryFee(delivery));
  }, [delivery, getDeliveryFee]);

  useEffect(() => {}, [serviceOrderData, nhsServicesPaymentIntents]);

  // Functions
  function getProducts(prods) {
    prods = prods.filter((e) => (e.price_trade > 0) & (e.quantity > 0));
    return prods.map((cartObj) => {
      return {
        productId: cartObj.productId,
        productName: cartObj.labels_fulldescription,
        productCost: cartObj.price_trade,
        productCount: cartObj.quantity,
        productVat: cartObj.price_vatcode,
        productTaxAmount: getCartProductTaxAmount(cartObj),
        productSubTotalAmount: getCartProductSubtotal(cartObj),
      };
    });
  }
  function getItemTotal(prod) {
    let t = 0;
    let price = prod.price_trade ? prod.price_trade : 0;
    let quantity = prod.quantity;
    let subtotal = price * quantity;
    let vatRate = vat[prod.price_vatcode];
    t += subtotal + subtotal * vatRate;
    return Math.round(t * 10) / 10;
  }
  function getTotals(prods) {
    let total = 0;
    // Iterate
    prods?.forEach((prod) => {
      let t = 0;
      let price = prod.price_trade ? prod.price_trade : 0;
      let quantity = prod.quantity;
      let subtotal = price * quantity;
      let vatRate = vat[prod.price_vatcode];
      t = subtotal + subtotal * vatRate;
      total += Math.round(t * 10) / 10;
    });
    return Math.round((total + deliveryFee) * 10) / 10;
  }
  function callStripIntent(data) {
    let payload = {
      amount: data.amount * 100,
      currency: "gbp",
      "automatic_payment_methods[enabled]": true,
      orderId: data.orderId,
      receipt_email: data.emailAddress,
      type: "product",
    };
    paymentIntentCallProxy(payload)
      .then((r) => {
        setStripIntentData(r.data);
        setClientSecret(r.data.client_secret);
        setLoadStripe(true);
        setStripeApiUp(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 503) {
          setStripeApiUp(false);
        }
      });
  }
  function getCartProductTaxAmount(prod) {
    let tax = 0;
    let rate = vat[prod.price_vatcode];
    let subtotal = prod.price_trade * prod.quantity;
    tax = Math.round(subtotal * rate * 10) / 10;
    return tax;
  }
  function getCartProductSubtotal(prod) {
    let tax = 0;
    let rate = vat[prod.price_vatcode];
    let subtotal = prod.price_trade * prod.quantity;
    tax = Math.round(subtotal * rate * 10) / 10;
    subtotal = Math.round((subtotal + tax) * 10) / 10;
    return subtotal;
  }
  function getDeliveryFee(del) {
    let d = orderDeliveryMethods.filter((i) => i.value === del);
    return d[0]?.amount || 0;
  }
  function getServiceTotals(servs) {
    let total = 0;
    // Iterate
    servs?.forEach((serv) => {
      let t = 0;
      let price = serv.isChargeable ? serv.serviceCost : 0;
      let quantity = 1;
      let subtotal = price * quantity;
      total += subtotal;
    });
    return Math.round(total * 10) / 10;
  }

  // Handlers
  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };
  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value) {
      setLastNameValidation({
        valid: false,
        message: "Last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };
  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };
  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneValidator(e.target.value)) {
      setPhoneNumberValidation({ valid: true, message: "" });
    } else {
      setPhoneNumberValidation({
        valid: false,
        message: "Please Enter Valid Phone Number of format 01111111111",
      });
    }
  };
  const address1Change = (e) => {
    setAddress1(e.target.value);
    if (!e.target.value) {
      setAddressValidaton({
        valid: false,
        message: "Address Line 1 Is Required",
      });
    } else {
      setAddressValidaton({ valid: true, message: "" });
    }
  };
  const address2Change = (e) => {
    setAddress2(e.target.value);
  };
  const cityChange = (e) => {
    setCity(e.target.value);
    if (!e.target.value) {
      setCityValidation({
        valid: false,
        message: "City Is Required",
      });
    } else {
      setCityValidation({ valid: true, message: "" });
    }
  };
  const countryChange = (e) => {
    setCountry(e.target.value);
  };
  const postCodeChange = (e) => {
    setPostCode(e.target.value);
    if (postalCodeValidator(e.target.value)) {
      setPostalCodeValidation({ valid: true, message: "" });
    } else {
      setPostalCodeValidation({
        valid: false,
        message: "Please Enter Valid Postal Code",
      });
    }
  };
  const selectCountry = (e) => {
    // Set Value
    setCountry(e.target.value);
  };
  let removeProductFromCart = (productId) => {
    removeFromCart(productId, cart);
    setActionCount((actionCount += 1));
  };
  const removeService = (index) => {
    serviceRemoveFromCart(index);
    setActionCount((actionCount += 1));
  };

  // Function to handle NHS service processing
  const processNhsServices = async (serviceOrderData) => {
    if (
      serviceOrderData?.nhs &&
      Object.keys(serviceOrderData?.nhs.data).length > 0 &&
      !nhsServicesPaymentIntents
    ) {
      try {
        const nhsInvoiceResponse = await createPractitionerInvoice(
          serviceOrderData.nhs,
        );
        setStripeApiUp(true);
        setNhsServicesPaymentIntents(nhsInvoiceResponse.data); // Save response in state

        // Finalize NHS bookings
        await finalizeNhsBookings({
          nhsServicesPaymentIntents: nhsInvoiceResponse.data,
          serviceOrderData,
        });
      } catch (err) {
        console.log("Error in NHS service processing:", err);
        setShowCheckout(true);
        if (err.response && err.response.status === 503) {
          setStripeApiUp(false);
        }
      }
    }
  };
  // Function to handle private service processing
  const processPrivateServices = async (serviceOrderData) => {
    if (
      serviceOrderData?.priv &&
      serviceOrderData.priv.total > 0 &&
      !privateServicesPaymentIntents
    ) {
      try {
        const privatePaymentResponse =
          await paymentIntentCallProxy(serviceOrderData);
        setStripIntentData(privatePaymentResponse.data);
        setClientSecret(privatePaymentResponse.data.client_secret);
        setLoadStripe(true);
        setStripeApiUp(true);
        setPrivateServicesPaymentIntents(privatePaymentResponse.data); // Save response in state
      } catch (err) {
        console.log("Error in private service processing:", err);
        setShowCheckout(true);
        if (err.response && err.response.status === 503) {
          setStripeApiUp(false);
        }
      }
    }
  };
  // Proceed To Checkout
  const proceedToCheckOut = async () => {
    setShowCheckout(false);
    let response = serviceOrderData;
    // PayLoad
    let payload = {
      serviceCart: serviceCart,
      billing_details: {
        emailId: emailAddress,
        firstName: firstName,
        lastName: lastName,
      },
    };

    // 1. Make Pre-Order Call
    try {
      // Check if `serviceOrderData` already exists in the state
      if (!serviceOrderData) {
        response = await initiateAppointments(payload);
        setServiceOrderData(response); // Save the response in state
      }

      // Process NHS and private services in parallel
      await Promise.all([
        processNhsServices(response),
        processPrivateServices(response),
      ]);

      console.log("All operations completed successfully!");
    } catch (err) {
      console.log("Error in handleAppointments:", err);
      setShowCheckout(true);
    }
  };

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- Cart</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      {/*Banner*/}
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundColor: "#202842" }}
      >
        <div className="container">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- Cart</h1>
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-4 text-light">
                  Your Shopping Cart
                </h3>

                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                  <ul className="breadcrumb bg-light rounded mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <Link to="/">ZipLaneRx</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/pharmacy">Pharmacy</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Shop Cart
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Gap*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          {/*Service Table*/}
          <div className="p-4 shadow bg-soft-dark">
            <ServiceCartTable
              data={serviceCart}
              removeService={removeService}
            />
            {/*Total Summary*/}
            <TotalSummary
              deliveryFee={deliveryFee}
              total={getServiceTotals(serviceCart)}
            />
          </div>

          <div className="p-4 mt-2 shadow bg-soft-dark">
            {/*Billed User*/}
            <BilledUser
              firstName={firstName}
              firstNameChange={firstNameChange}
              firstNameValidation={firstNameValidation}
              lastName={lastName}
              lastNameChange={lastNameChange}
              lastNameValidation={lastNameValidation}
              emailAddress={emailAddress}
              emailAddressChange={emailAddressChange}
              emailValidation={emailValidation}
              data={serviceCart}
            />
            {/*Checkout Button*/}
            {showCheckout ? (
              <div className="pt-2 text-end">
                <Button
                  className="btn btn-primary"
                  style={{ minWidth: "fit-content" }}
                  onClick={() => {
                    proceedToCheckOut(data);
                  }}
                  disabled={!validForm}
                >
                  Checkout
                </Button>
              </div>
            ) : (
              !loadStrip && (
                <div className="pt-2 text-end">
                  <Button
                    className="btn btn-primary"
                    style={{ minWidth: "fit-content" }}
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Please Wait
                  </Button>
                </div>
              )
            )}
          </div>

          {/*Gap*/}
          <div className="row">
            <div className="col-12"></div>
          </div>

          {serviceOrderData && loadStrip ? (
            <div className="p-4 mt-2 shadow bg-soft-dark">
              <Elements stripe={stripePromise} options={options}>
                <PaymentForm
                  data={{
                    serviceOrderData,
                    nhsServicesPaymentIntents,
                    privateServicesPaymentIntents,
                  }}
                  clientSecret={clientSecret}
                />
              </Elements>
            </div>
          ) : data && !stripeApiUp ? (
            <div className="d-grid">
              <p>
                {" "}
                <span className="fw-bolder text-danger">
                  Stripe API is down{" "}
                </span>
                <span className="text-primary">{firstName}!</span>
              </p>
              <p>
                <span className="text-danger">Please try again later!</span>
              </p>
            </div>
          ) : null}
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
