import React from "react";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import moment from "moment";

function ServiceCartTable({ data, removeService }) {
  return (
    <div className="row text-start">
      {!data || data.length === 0 ? (
        <div className="col-12" style={{ marginTop: 5, marginBottom: 10 }}>
          <h5>Your Cart is Empty!</h5>
        </div>
      ) : (
        <div className="col-12" style={{ marginTop: 0, marginBottom: 0 }}>
          <h5>Your Cart</h5>
        </div>
      )}

      <div className="col-12">
        <div className="table-responsive bg-white shadow rounded">
          <table className="table table-center table-padding mb-0">
            <thead>
              <tr>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "20px" }}
                ></th>
                <th className="border-bottom p-3" style={{ minWidth: "300px" }}>
                  Service
                </th>
                <th className="border-bottom p-3" style={{ minWidth: "160px" }}>
                  Name
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Price
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Date
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Time
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    {/*Remove*/}
                    <td className="h5 p-3 text-center">
                      <Link
                        to=""
                        className="text-danger"
                        onClick={() => {
                          removeService(index);
                        }}
                      >
                        <LiaTimesSolid />
                      </Link>
                    </td>
                    {/*Service*/}
                    <td className="p-3">{item.serviceName}</td>
                    {/*Name*/}
                    <td className=" p-3">
                      {`${item.firstName} ${item.lastName}`}
                    </td>
                    {/*Price*/}
                    <td className=" shop-list p-3">
                      {item.isChargeable
                        ? `${item.currencySymbol} ${item.serviceCost}`
                        : `${item.currencySymbol} 0`}
                    </td>
                    {/*Date*/}
                    <td className=" shop-list p-3">
                      {moment(item.start).format("LL")}
                    </td>
                    {/*Time*/}
                    <td className=" shop-list p-3">
                      {moment(item.start).format("HH:mm")}
                    </td>
                    {/*Total*/}
                    <td className=" shop-list p-3">
                      {item.isChargeable
                        ? `${item.currencySymbol} ${item.serviceCost}`
                        : `${item.currencySymbol} 0`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ServiceCartTable;
