import React, { useEffect, useState } from "react";
import "./services-search.css";
import { Link } from "react-router-dom";

function PathServiceExplore({
  services,
  selectService,
  selectedServiceObject,
  resetSelection,
}) {
  const [servicesActiveIndex, setServicesActiveIndex] = useState(0);
  const [nhsServices, setNhsServices] = useState([]);
  const [privateServices, setPrivateServices] = useState([]);
  const [privateServicesGrouped, setPrivateServicesGrouped] = useState([]);
  const [privateServicesCategory, setPrivateServicesCategory] = useState(null);

  useEffect(() => {
    setNhsServices(services.filter((service) => service.isNhs));
    setPrivateServices(services.filter((service) => !service.isNhs));
  }, [services]);

  useEffect(() => {
    let grouped = privateServices.reduce((acc, current) => {
      const key = current.srvc_type;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(current);
      return acc;
    }, {});
    setPrivateServicesGrouped(grouped);
  }, [privateServices]);

  return (
    <div className="col-12 mt-0">
      <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-top shadow overflow-hidden bg-light mt-3 mb-0"></ul>
      <div
        className="col-12"
        style={{
          height: "fit-content",
          maxHeight: "250pt",
          boxSizing: "border-box",
          margin: "0",
          overflowY: "auto",
          contentVisibility: "auto",
          overflowMargin: "content-box",
        }}
      >
        <div className="tab-pane fade show active shadow rounded py-0 px-0">
          <div className="table-responsive bg-white shadow rounded-bottom mb-0">
            {servicesActiveIndex === 0 ? (
              <div className="accordion">
                {Object.keys(privateServicesGrouped).map((item, index) => {
                  return (
                    <div
                      className="accordion-item border rounded mt-2 mx-2"
                      key={index}
                    >
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={`${
                            privateServicesCategory === item ? "" : "collapsed"
                          } accordion-button border-0 bg-light px-2`}
                          type="button"
                          onClick={() => {
                            privateServicesCategory !== item
                              ? setPrivateServicesCategory(item)
                              : setPrivateServicesCategory(null);
                          }}
                        >
                          {item !== "null" ? item : "Other"}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className={`${
                          privateServicesCategory === item ? "show" : ""
                        } accordion-collapse border-0 collapse`}
                      >
                        <div className="accordion-body text-muted">
                          <table
                            className="table mb-0 table-center table-wrap"
                            style={{ fontSize: "small" }}
                          >
                            <thead className="text-start">
                              <tr>
                                <th className="border-bottom p-1" scope="col">
                                  Service
                                </th>
                                <th className="border-bottom p-1" scope="col">
                                  Description
                                </th>
                                <th className="border-bottom p-1" scope="col">
                                  Result Availability
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-start">
                              {privateServicesGrouped[item].map((i, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`p-1 ${
                                      selectedServiceObject === i
                                        ? "table-active"
                                        : ""
                                    } shadow-primary`}
                                    onClick={() => {
                                      selectService(i);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td className="p-1">
                                      {i.serviceName.toUpperCase()}
                                    </td>
                                    <td className="p-1">
                                      {i.serviceDescription}
                                    </td>
                                    <td className="p-1">
                                      {i.resultAvailability}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PathServiceExplore;
