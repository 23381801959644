// footer component
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiLinkedin } from "react-icons/fi";

class Footer extends Component {
  render() {
    return (
      <footer className="">
        <div className="container" style={{ height: "fit-content" }}>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-0 mb-md-0 pb-0 pb-md-0 mt-0 pt-0">
              <p className="me-xl-5" style={{ marginTop: -20 }}>
                Redefining healthcare access and practitioner success!
              </p>
            </div>

            <div className="col-xl-7 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-0 pt-sm-0">
                  <ul className="list-unstyled social-icon footer-social mb-0 mt-0 pt-0 text-md-start text-center">
                    <li className="list-inline-item">
                      <Link
                        to="https://www.linkedin.com/company/ziplanerx/"
                        target="_blank"
                        className="btn btn-icon btn-pills btn-primary"
                        title="Cart"
                      >
                        <FiLinkedin />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*</div>*/}

          {/*<div className="container mt-5">*/}
          <div className="row">
            <div className="pt-0 mt-0">
              <div className="row align-items-center">
                <div className="col-sm-3">
                  <div className="text-sm-start text-center">
                    <p className="mb-0">
                      <script>document.write(new Date().getFullYear())</script>
                      © ZipLaneRx. 2024
                    </p>
                  </div>
                </div>

                <div className="col-sm-6 mt-2 mt-sm-0">
                  <ul className="list-unstyled footer-list text-center mb-0">
                    <li className="list-inline-item">
                      <Link to="/aboutus" className="text-foot me-2">
                        About
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/aboutus/terms" className="text-foot me-2">
                        Terms
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/aboutus/privacy" className="text-foot me-2">
                        Privacy
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/aboutus/cookies" className="text-foot me-2">
                        Cookies
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/aboutus/faqs" className="text-foot me-2">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-3 mt-4 mt-sm-0">
                  <ul className="list-unstyled footer-list text-center mb-0">
                    <li className="list-inline-item">
                      <img
                        src={"assets/images/support.svg"}
                        className="l-dark"
                        height="75"
                        alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
                      />
                      <p className="mb-0">Proud Supporters</p>
                      <p>Community Practitioners</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <a
              href="#body"
              onClick={window.topFunction}
              id="back-to-top"
              className="back-to-top fs-5 rounded-pill text-center bg-primary justify-content-center align-items-center"
            >
              <i data-feather="arrow-up" className="fea icon-sm"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
