import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { faqQuestions } from "../../data/data";
import { Helmet } from "react-helmet-async";

export default function FAQS() {
  // State Variables
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>ZipLaneRx -- FAQs</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/bg/question.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark">
          <h1 style={{ opacity: "0" }}>ZipLaneRx -- FAQs</h1>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FAQs
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <h3 className="card-title">Frequently Asked Questions:</h3>
              <div className="accordion">
                {faqQuestions.map((item, index) => {
                  return (
                    <div
                      className="accordion-item border-0 rounded mt-2 mx-2"
                      key={index}
                    >
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={`${
                            selectedQuestion === item.id ? "" : "collapsed"
                          } accordion-button border-0 bg-light px-2`}
                          type="button"
                          onClick={() => {
                            selectedQuestion !== item.id
                              ? setSelectedQuestion(item.id)
                              : setSelectedQuestion(null);
                          }}
                        >
                          {item !== "null" ? item.question : null}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className={`${
                          selectedQuestion === item.id ? "show" : ""
                        } accordion-collapse border-0 collapse`}
                      >
                        <div className="accordion-body text-muted">
                          <h6>{item.answer}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
